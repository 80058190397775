import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';


import InquiryFormInner from './InquiryFormInner';
import { sendInquiry, resetInquiryForm } from '../../actions/inquiryForm';

class InquiryForm extends Component {

  componentWillReceiveProps(nextProps){
    if(this.props.inquiry.inquirySentStatus !== 'sent' && nextProps.inquiry.inquirySentStatus === 'sent'){
      this.props.toggleInquiryModal();
    }
  }

  handleClickSubmit = (values) => {
    const { dispatch } = this.props;
    dispatch(sendInquiry(values));
  }



  onModalClosed = () => {
    const { dispatch } = this.props;
    dispatch(resetInquiryForm());
    dispatch(reset('inquiry'));
    reset();
  }

  render() {
    return (
      <InquiryFormInner
        {...this.props}
        onSubmit={this.handleClickSubmit}
        onModalClosed={this.onModalClosed}
        onModalOpened={this.onModalOpened}
      />
    );
  }
}

const mapStateToProps = ( state ) => ({
  inquiry: state.inquiryEmail
})

export default connect(mapStateToProps)(InquiryForm);