import React from "react";
import PropTypes from "prop-types";

import "./SocialIconsFooter.scss";

const SocialIconsFooter = (props) => {
  const { facebookUrl, twitterUrl, instagramUrl, pinterestUrl, linkedinUrl, youtubeUrl, tiktokUrl} = props;

  return (
    <div className="social-icons-footer">
      <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={"/svgs/facebook.png"}
          alt="Agency21 International"
          className="footer-icon-social"
        />
      </a>
      <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={"/svgs/twitter.png"}
          alt="Agency21 International"
          className="footer-icon-social"
        />
      </a>
      <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={"/svgs/instagram.png"}
          alt="Agency21 International"
          className="footer-icon-social"
        />
      </a>
      <a href={pinterestUrl} target="_blank" rel="noopener noreferrer">
        <img src={'/svgs/pinterest.png'} 
        alt='Agency21 International' 
        className= "footer-icon-social" />
        </a>
        <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={"/svgs/linkedin.png"}
          alt="Agency21 International"
          className="footer-icon-social"
        />
      </a>
      <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={"/svgs/youtube.png"}
          alt="Agency21 International"
          className="footer-icon-social"
        />
      </a>
      <a href={tiktokUrl} target="_blank" rel="noopener noreferrer">
        <img
          src={"/svgs/tiktok.png"}
          alt="Agency21 International"
          className="footer-icon-social"
        />
      </a>
    </div>
  );
};

SocialIconsFooter.propTypes = {
  facebookUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  pinterestUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  youtubeUrl: PropTypes.string,
  tiktokUrl: PropTypes.string,
};
SocialIconsFooter.defaultProps = {
  facebookUrl: "https://www.facebook.com/Agency21pk/",
  twitterUrl: "https://twitter.com/agency21int",
  instagramUrl: "https://www.instagram.com/agency21pk/",
  pinterestUrl: "https://www.pinterest.com/agency21pk",
  linkedinUrl: "https://www.linkedin.com/company/agency21pk",
  youtubeUrl: "https://www.youtube.com/c/Agency21pk",
  tiktokUrl:  "https://www.tiktok.com/@agency21pk",
};

export default SocialIconsFooter;
