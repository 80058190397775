import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Router, withRouter } from "react-router-dom";
import { routes } from "../../config/routes";

import "./Footer.scss";
// import SocialIcons from '../SocialIcons';
import ContactAddress from "../ContactAddress";
import SocialIconsFooter from "../SocialIconsFoorter";

class Footer extends Component {
  render() {
    const { history } = this.props;
    return (
      <div id="footer">
        <Container style={{ paddingBottom: "28px" }}>
          <Row style={{ margin: 0 }}>
            <Col md="4" sm="12" xs="12" style={{ marginBottom: "16px" }}>
              <img
                className="logo-foot"
                src="/images/agency_new_logo.png"
                alt="Agency21 International"
              />
              <p className="footer-text">
                “Agency21 International, Pakistan's Biggest Estate Agency ,
                simplifies your Real Estate experience and ensures transparent
                property transactions.”
              </p>
            </Col>
            <Col md="2" sm="12" xs="12" className="footer-section">
              {/* <h4>
                <b>Agency21 International</b>
              </h4> */}
              <ul className="footer-ul-links">
                <li
                  onClick={() => {
                    history.push("/about");
                  }}
                >
                  <a className="footer-ul-links" href="#about">
                    About Us
                  </a>
                </li>
                <li
                  onClick={() => {
                    history.push("/terms");
                  }}
                >
                  Terms & Conditions
                </li>
                <li
                  onClick={() => {
                    history.push("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </li>
                <li
                  onClick={() => {
                    history.push("/contact");
                  }}
                >
                  <a className="footer-ul-links" href="#contact">
                    Contact Us
                  </a>
                </li>
                <li
                  onClick={() => {
                    history.push("/careers");
                  }}
                >
                  <a className="footer-ul-links">Careers</a>
                </li>
                <li
                  onClick={() => {
                    history.push("/blog");
                  }}
                >
                  <a className="footer-ul-links">Blog</a>
                </li>
                <li
                  onClick={() => {
                    history.push("/iso");
                  }}
                >
                  ISO Certification
                </li>
              </ul>
            </Col>
            <Col md="3" sm="12" xs="12" className="footer-section contAdd">
              <h4>
                <b>Head Office</b>
              </h4>
              <ContactAddress
                email="info@agency21.com.pk"
                phone="(051) 111 021 111"
                address=" 2nd Floor, Deen Pavilion 94-East Blue Area, Islamabad, Pakistan"
                mlink="https://goo.gl/maps/U5fRmS7A1NM7LaBJ8"
              />
            </Col>
            <Col md="3" sm="12" xs="12" className="footer-section contAdd">
              <h4>
                <b>Corporate Office</b>
              </h4>
              <ContactAddress
                email="info@agency21.com.pk"
                phone="(051) 111 021 111"
                address="Plaza # 91, Civic Center Bahria Phase 4, Islamabad, Pakistan "
                mlink="https://goo.gl/maps/4kmodLnioXVWBLWL9"
              />
              <div style={{ display: "flex" }}>
                <img
                  src="/images/SGS_ISO_9001_round_TCL1.png"
                  alt=""
                  width="22%"
                  style={{ objectFit: "contain" }}
                />
                <div className="isoText">ISO 9001 CERTIFIED</div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="divider-color">
          <div className="footer-icons">
            <SocialIconsFooter />
          </div>
          <div className="copyrights-container">
            <p>&copy; Agency21 {new Date().getFullYear()}</p>
            <p
              onClick={() => {
                history.push("/terms");
              }}
            >
              Terms and Conditions
            </p>
            <p
              onClick={() => {
                history.push("/privacy-policy");
              }}
            >
              Privacy policy
            </p>
            <p
              onClick={() => {
                history.push("/contact");
              }}
            >
              <a href="#contact" className="cus-tag">
                Contact Us
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);

//https://res.cloudinary.com/agency21/image/upload/v1536840897/logos/logo_footer.png (previous footer logo)
