import { reducer as formReducer } from 'redux-form';

const inquiry = 'inquiry';


export default formReducer.plugin({
  [inquiry]: (state, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
})