import { isEmpty } from "lodash";
import { toast } from "react-toastify";

import {
  FETCH_PROJECTVIDEOS,
  FETCH_PROJECTVIDEOS_SUCCESS,
  FETCH_PROJECTVIDEOS_FAILURE,
  DELETE_PROJECTVIDEOS,
  DELETE_PROJECTVIDEOS_FAILURE,
  DELETE_PROJECTVIDEOS_SUCCESS,
  REQUEST_CREATE_PROJECTVIDEOS,
  CREATE_PROJECTVIDEOS_FAILURE,
  CREATE_PROJECTVIDEOS_SUCCESS,
  REQUEST_UPDATE_PROJECTVIDEOS,
  UPDATE_PROJECTVIDEOS_FAILURE,
  UPDATE_PROJECTVIDEOS_SUCCESS,
} from "../../actions/projectVideos";

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  isDeletingPROJECTVIDEOS: false,
  data: [],
  pagination: {
    per_page: null,
    current_page: null,
    total_pages: null,
    total_records: null,
  },
  isUploadingPROJECTVIDEOS: false,
  PROJECTVIDEOSUploadedSuccessfuly: false,
};

export const projectVideos = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECTVIDEOS:
      return Object.assign({}, state, { isLoading: true });

    case FETCH_PROJECTVIDEOS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isDataAvailable: Object.keys(action.payload).length > 0,
        data: action.payload,
        // pagination: action.payload.pagination
      });

    case DELETE_PROJECTVIDEOS:
      return Object.assign({}, state, { isDeletingPROJECTVIDEOS: true });

    case DELETE_PROJECTVIDEOS_SUCCESS:
    case DELETE_PROJECTVIDEOS_FAILURE:
      return Object.assign({}, state, { isDeletingPROJECTVIDEOS: false });

    case FETCH_PROJECTVIDEOS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isDataAvailable: false,
      });

    case REQUEST_CREATE_PROJECTVIDEOS:
      return Object.assign({}, state, {
        isUploadingPROJECTVIDEOS: true,
        PROJECTVIDEOSUploadedSuccessfuly: false,
      });

    case CREATE_PROJECTVIDEOS_SUCCESS: {
      toast.success("PROJECTVIDEOS Created Successfuly!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        isUploadingPROJECTVIDEOS: false,
        PROJECTVIDEOSUploadedSuccessfuly: true,
      });
    }
    case CREATE_PROJECTVIDEOS_FAILURE: {
      toast.error("Could not upload PROJECTVIDEOS :( Please try again", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        isUploadingPROJECTVIDEOS: false,
        PROJECTVIDEOSUploadedSuccessfuly: false,
      });
    }

    // case REQUEST_CREATE_PROJECTVIDEOS:
    //   return Object.assign({}, state, { isUploadingPROJECTVIDEOS: true, PROJECTVIDEOSUploadedSuccessfuly: false });

    // case CREATE_PROJECTVIDEOS_SUCCESS:{
    //   toast.success('PROJECTVIDEOS Created Successfuly!')
    //   return Object.assign({}, state, { isUploadingPROJECTVIDEOS: false, PROJECTVIDEOSUploadedSuccessfuly: true });
    // }
    // case CREATE_PROJECTVIDEOS_FAILURE: {
    //   toast.error('Could not upload PROJECTVIDEOS :( Please try again')
    //   return Object.assign({}, state, { isUploadingPROJECTVIDEOS: false, PROJECTVIDEOSUploadedSuccessfuly: false })
    // }

    case REQUEST_UPDATE_PROJECTVIDEOS:
      return Object.assign({}, state, {
        isUploadingPROJECTVIDEOS: true,
        PROJECTVIDEOSUploadedSuccessfuly: false,
      });

    case UPDATE_PROJECTVIDEOS_SUCCESS: {
      toast.success("PROJECTVIDEOS Created Successfuly!");
      return Object.assign({}, state, {
        isUploadingPROJECTVIDEOS: false,
        PROJECTVIDEOSUploadedSuccessfuly: true,
      });
    }
    case UPDATE_PROJECTVIDEOS_FAILURE: {
      toast.error("Could not update PROJECTVIDEOS :( Please try again");
      return Object.assign({}, state, {
        isUploadingPROJECTVIDEOS: false,
        PROJECTVIDEOSUploadedSuccessfuly: false,
      });
    }

    default:
      return state;
  }
};
