import React from 'react'
import ReactGA from 'react-ga'
import config from './config'
import fuzzy from 'fuzzy'
import sanitizeHtml from 'sanitize-html'

// var currencyRates = {
//   AED : 10,
//   AUD : 30,
//   CAS : 40,
//   USD : 102,
//   EUR : 100,
//   CNY : 9,
//   GBP : 18,
// }

export function sanitize(html, allowNone = false) {
  if(allowNone) {
    return sanitizeHtml(html, {allowedTags: [], allowedAttributes: {}})
  } else {
    return sanitizeHtml(html)
  }
}

export function checkIfHtmlEmpty(html) {
  return sanitizeHtml(html, {
    allowedTags: [],
  })
}

export function trimmedPhone  (phone) {
   
  if(phone.startsWith('+920')){
    return phone.substring(4)
  }
  else if(phone.startsWith('+92')){
    return phone.substring(3)
  }
  else if(phone.startsWith('920')){
    return phone.substring(3)
  }
  else if(phone.startsWith('92')){
    return phone.substring(2)
  }
  else if(phone.startsWith('0')){
    return phone.substring(1)
  }
  else return phone

}


export function validateNumber (number) {
  if(number.includes('-') && number.length > 13){
      return true
  }
  else if (number.length > 11){
      return true
  }
  else {
      return false
  }
}

function onlyDigit(n) {
  //Arrays to store the string equivalent of the number to convert in words
  var units = ['', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  var randomer = ['', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
  var tens = ['', '10', '2', '3', '4', '5', '6', '7', '8', '9'];
  var r = 0;
  var num = parseInt(n);
  var str = "";
  var pl = "";
  var tenser = "";
  while (num >= 1) {
    r = parseInt(num % 10);
    tenser = r + tenser;
    if (tenser <= 19 && tenser > 10) //Logic for 10 to 19 numbers
    {
      str = randomer[tenser - 10];
    }
    else {
      if (pl === 0)        //If units place then call units array.
      {
        str = units[r];
      }
      else if (pl === 1)    //If tens place then call tens array.
      {
        str = tens[r] + str;
      }
    }
    if (pl === 2)        //If hundreds place then call units array.
    {
      str = units[r] + str;
    }

    num = parseInt(num / 10);
    pl++;
  }
  return str;
}

export function capitalize (string){
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stripHTML(html) {
  if(html) {
    return sanitizeHtml(html, {allowedTags: []})
  } else {
    return ''
  }
}

export function convertPrice(price, currency, currencyRates) {
  if(currency === 'PKR') {
    if(price >= 10000000) {
      // return `${Math.round(price/10000000*100)/100}${price == 20000000 ? '+' : ''} crore`;
      return `${Math.round(price/10000000*100)/100} crore`
    }
    if(price >= 100000) {
      // return `${Math.round(price/100000*100)/100}${price == 300000 ? '+' : ''} lac`;
      return `${Math.round(price/100000*100)/100} lac`
    }
    // if(price >= 1000) {
    //   return `${Math.round(price/1000*100)/100}k`;
    // }
    return price
  }
  else {
    var curr = currencyRates && currency ? ((Math.round(price * parseFloat(currencyRates[currency]) / 1000)*1000) > 1000 
      ? (Math.round(price * parseFloat(currencyRates[currency]) / 1000)*1000).toLocaleString() 
      : (Math.round(price * parseFloat(currencyRates[currency])) ) ) : 0
    return curr
  }
}

export function numToString(x) {
  var r = 0;
  var txter = x;
  var sizer = txter.length;
  var numStr = "";
  if (isNaN(txter)) {
    return (" Invalid number");
    // exit();
  }
  var n = parseInt(x);
  var places = 0;
  var str = "";
  var entry = 0;
  while (n >= 1) {
    r = parseInt(n % 10);

    if (places < 3 && entry === 0) {
      numStr = txter.substring(txter.length - 0, txter.length - 3) // Checks for 1 to 999.
      if (numStr !== '000') {
        str = onlyDigit(numStr);
        if(numStr[2] === '0') {
          str = str + '0'
        }
      }
      // str=onlyDigit(numStr); //Calls function for last 3 digits of the value.
      entry = 1;
    }

    if (places === 3) {
      numStr = txter.substring(txter.length - 5, txter.length - 3)
      if (numStr !== "" && numStr !== "00") {
        if (numStr[1] === '0' && numStr !== '10') {
          str = onlyDigit(numStr) + "0 Thousand " + str;
        } else {
          str = onlyDigit(numStr) + " Thousand " + str;
        }
        // str=onlyDigit(numStr)+ " Thousand "+str;
      }
    }

    if (places === 5) {
      numStr = txter.substring(txter.length - 7, txter.length - 5) //Substring for 5 place to 7 place of the string
      if (numStr !== "" && numStr !== "00") {
        if (numStr[1] === '0' && numStr !== '10') {
          str = onlyDigit(numStr) + "0 Lakhs " + str;
        } else {
          str = onlyDigit(numStr) + " Lakhs " + str;
        }
        // str=onlyDigit(numStr)+ " Lakhs "+str; //Appends the word lakhs to it
      }
    }

    if (places === 6) {
      numStr = txter.substring(txter.length - 9, txter.length - 7)  //Substring for 7 place to 8 place of the string
      if (numStr !== "" && numStr !== "00") {
        if (numStr[1] === '0' && numStr !== '10') {
          str = onlyDigit(numStr) + "0 Crores " + str;
        } else {
          str = onlyDigit(numStr) + " Crores " + str;
        }       //Appends the word Crores
      }
    }

    n = parseInt(n / 10);
    places++;
  }
  return (str);
}

export function convertUnit(unit) {
  var size = {
    standard: 'Standard',
    kanal: 'Kanal',
    marla: 'Marla',
    sqft: <span>SqFt</span>,
    sqyd: <span>SqYd</span>,
    sqm: <span>M<sup>2</sup></span>,
  }
  return size[unit]
}


export function convertSize(size, unit, marla_size = 250) {
  switch(unit) {
    case 'sqm':
      return (size / 10.7639).toFixed(2)
    case 'sqyd':
      return (size / 9).toFixed(2)
    case 'kanal':
      return (size / (20 * marla_size)).toFixed(2)
    case 'marla':
      return (size / marla_size).toFixed(2)
    default:
      return size
  }
}

export function checkForHttps(url) {
  var a = url ? url.split('https') : ''
  if (url && a[0] === url) {
    var b = a[0].split('http')
    var c = 'https' + b[1]
    return c
  } else {
    return url
  }
}

export function hiderUrls(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
        return '(website hidden by graana.com)';
    })
}

export function generateImageUrl(url, resolution = '840') {
  if (url && url.includes('original')) {
    return `${config.s3URL}${url
      ? url.replace('original', resolution)
      : url.replace('https://images.graana.com', '')}`
  }
  // return url?.includes('cloudinary') ? '/about/vision.png' : url
  // return url?.includes('cloudinary')
  //   ? url.replace('https://res.cloudinary.com', '')
  //   : url
  return url
}

export function s3URL(url) {
  if (url) {
    return `${config.s3URL}${url}`
  }
}

export function imgUpload(url, type, watermark = false) {
  return `${config.s3URL}${url}`
  if (type) {
    var a = url ? url.split('res.cloudinary.com') : ''
    if (a[0] !== url) {
      var a = url.split('/upload')
      if (watermark) {
        var b = a[0] + `/upload/o_40,h_0.15,l_gwm,fl_relative/${type},q_auto,f_auto` + a[1]
      }
      else {
        var b = a[0] + `/upload/${type},q_auto,f_auto` + a[1]
      }
      return checkForHttps(b)
    } else {
      return checkForHttps(url)
    }
  }
}

var previousTitle = ''
export function logPageView(title) {
  if(title === previousTitle) {
    return false;
  } else {
    previousTitle = title;
    if(config.analytics) {
      if(process.browser){
      ReactGA.set({page: window.location.pathname + window.location.search})
      ReactGA.pageview(window.location.pathname + window.location.search, null, title)
      }
    }
  }
}


export function validateSlug (name) {
  if (name && typeof name == 'string') {
    return name.split(' ').join('_').split('.').join('_').split('%').join('_').split('/').join('~');
  } else {
    return '';
  }
}

export function upperCase (string){
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const filterOptions = (candidate, input) => {
  if (input) {
		var fltrd = fuzzy.filter(input, [candidate], {extract: (e) => {return e.label}} )
		if(!fltrd.length && fltrd.length) {
			if (fltrd[0].score >=1) {
				return fltrd[0].original
			} else {

			return false;
			}
		} else {
			return false;
		}
    // return candidate.value === customOptions[0].value;
  } else {
		return true;
	}
};

