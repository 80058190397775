import { CALL_API } from "../apiMiddleware";

export const ADD_GALLERY_EVENT = "ADD_GALLERY_EVENT";
export const ADD_GALLERY_EVENT_SUCCESS = "ADD_GALLERY_EVENT_SUCCESS";
export const ADD_GALLERY_EVENT_FAILURE = "ADD_GALLERY_EVENT_FAILURE";

export const addGalleryEvent = ({
  title,
  description,
  files,
  videos,
  date,
  thumbnail
}) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append("files", file);
  });
  formData.set("videos", videos);
  formData.set("title", title);
  formData.set("description", description);
  formData.set("date", date.format("YYYY-MM-DD"));
  formData.set("thumbnail", thumbnail);
  return {
    [CALL_API]: {
      types: [
        ADD_GALLERY_EVENT,
        ADD_GALLERY_EVENT_SUCCESS,
        ADD_GALLERY_EVENT_FAILURE
      ],
      endpoint: "/api/events/event-upload",
      options: {
        method: "POST",
        body: formData
      }
    }
  };
};

export const getAccessToken = () => ({
  [CALL_API]: {
    types: ["GET_TOKEN", "GET_TOKEN_SUCCESS", "GET_TOKEN_FAILURE"],
    endpoint: "/auth/unlock",
    apiSource: "http://localhost:3000",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ password: "asd123ASD!@#" })
    }
  }
});
