import { CALL_API } from '../apiMiddleware';
import axios from "axios"
export const SET_WANTED_PURPOSE = 'SET_WANTED_PURPOSE';
export const SET_WANTED_PROJECT_ID = 'SET_WANTED_PROJECT_ID';
export const SET_WANTED_TYPE = 'SET_WANTED_TYPE';
export const SET_WANTED_PROJECT_TYPE = 'SET_WANTED_PROJECT_TYPE';
export const SET_WANTED_SUBTYPE = 'SET_WANTED_SUBTYPE';
export const SET_WANTED_CITY = 'SET_WANTED_CITY';
export const CLEAR_WANTED_CITY = 'CLEAR_WANTED_CITY';
export const CLEAR_WANTED_CITY_ID = 'CLEAR_WANTED_CITY_ID';
export const ADD_WANTED_AREA = 'ADD_WANTED_AREA';
export const ADD_WANTED_AREA_ID = 'ADD_WANTED_AREA_ID';
export const DELETE_WANTED_AREA = 'DELETE_WANTED_AREA';
export const SET_WANTED_SIZE = 'SET_WANTED_SIZE'; 
export const SET_WANTED_SIZE_UNIT = 'SET_WANTED_SIZE_UNIT';
export const SET_WANTED_MAX_PRICE = 'SET_MAX_PRICE';
export const SET_WANTED_MIN_PRICE = 'SET_WANTED_MIN_PRICE';
export const SET_WANTED_DESCRIPTION = 'SET_WANTED_DESCRIPTION';
export const SET_WANTED_PHONE = 'SET_WANTED_PHONE';
export const SET_WANTED_FULLNAME = 'SET_WANTED_FULLNAME';
export const RESET_WANTED_SPEC_FORM = 'RESET_WANTED_SPEC_FORM';
export const SET_INTERMEDIARY_AREANAME = 'SET_INTERMEDIARY_AREANAME';
export const REQUEST_SUBMIT_WANTED_FORM = 'REQUEST_SUBMIT_WANTED_FORM';
export const REQUEST_SUBMIT_WANTED_FORM_SUCCESS = 'REQUEST_SUBMIT_WANTED_FORM_SUCCESS';
export const REQUEST_SUBMIT_WANTED_FORM_FAILURE = 'REQUEST_SUBMIT_WANTED_FORM_FAILURE';
export const SENDING_TO_ASSIGN = 'SENDING_TO_ASSIGN';



export const setWantedPurpose = (purpose) => ({
  type: SET_WANTED_PURPOSE,
  payload: purpose
})
export const setWantedType = (type) => ({
  type: SET_WANTED_TYPE,
  payload: type
})


export const setWantedSubtype = (subtype) => ({
  type: SET_WANTED_SUBTYPE,
  payload: subtype
})

export const setWantedCity = (city) => ({
  type: SET_WANTED_CITY,
  payload: city
})

export const setWantedCityId = (cityId) => ({
  type: CLEAR_WANTED_CITY_ID,
  payload: cityId
})

export const clearWantedCity = () => ({
  type: CLEAR_WANTED_CITY
})

export const addWantedArea = (area) => ({
  type: ADD_WANTED_AREA,
  payload: area.id
})

export const addWantedAreaId = (area) => ({
  type: ADD_WANTED_AREA_ID,
  payload: area.id
})

export const deleteWantedArea = (areaId) => ({
  type: DELETE_WANTED_AREA,
  payload: areaId
})

export const setWantedSize = (size) => ({
  type: SET_WANTED_SIZE,
  payload: size
})

export const setWantedSizeUnit = (sizeUnit) => ({
  type: SET_WANTED_SIZE_UNIT,
  payload: sizeUnit
})

export const setWantedMaxPrice = (mp) => ({
  type: SET_WANTED_MAX_PRICE,
  payload: mp
})

export const setWantedDescription = (desc) => ({
  type: SET_WANTED_DESCRIPTION,
  payload: desc
})

export const setWantedPhone = (phone) => ({
  type: SET_WANTED_PHONE,
  payload: phone
})
export const setWantedProject = (projectId) => ({
  type: SET_WANTED_PROJECT_ID,
  payload: projectId
})
export const setWantedProjectType = (armsProjectTypeId) => ({
  type: SET_WANTED_PROJECT_TYPE,
  payload: armsProjectTypeId
})
export const setWantedFullName = (fullname) => ({
  type: SET_WANTED_FULLNAME,
  payload: fullname
})

export const setWantedMinPrice = (minPrice) => ({
  type: SET_WANTED_MIN_PRICE,
  payload: minPrice
})

export const setIntermediaryAreaname = (areaname) => ({
  type: SET_INTERMEDIARY_AREANAME,
  payload: areaname
})

export const resetWantedSpecForm = () => ({
  type: RESET_WANTED_SPEC_FORM
})

export const requestSubmitWantedForm = (values) => ({
  [CALL_API]: {
    types: [REQUEST_SUBMIT_WANTED_FORM, REQUEST_SUBMIT_WANTED_FORM_SUCCESS, REQUEST_SUBMIT_WANTED_FORM_FAILURE],
    endpoint: '/api/wanted',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    },
  }
})

export const sendingToAssign = (id) => ({
  [CALL_API]: {
    types: [REQUEST_SUBMIT_WANTED_FORM, SENDING_TO_ASSIGN],
    endpoint: `/api/wanted/sendingToAssign/${id}`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({status: id})
    },
  }
})
