import React from 'react';
import './LoadingState.scss';

const LoadingState = () => (
  <div className="lds-ripple">
    <div />
    <div />
  </div>
);

export default LoadingState;