import { 
    FETCH_CITIES, FETCH_CITIES_SUCCESS, FETCH_CITIES_FAILURE,
} from '../../actions/cities';


const initialState = {
    data: [],
    isLoading: false,
    isDataAvailable: false
}

export const cities = (state=initialState, action) => {
    switch(action.type){
        case FETCH_CITIES:
            return Object.assign({}, state, {isLoading: true});

        case FETCH_CITIES_SUCCESS:
            return Object.assign({}, state, {isLoading: false, isDataAvailable: action.payload.length > 0, data: action.payload});

        case FETCH_CITIES_FAILURE:
            return Object.assign({}, state, initialState);

        default:
            return state;
    }
}
