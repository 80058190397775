import { CALL_API } from "../apiMiddleware";

export const FETCH_ALL_JOBS = "FETCH_ALL_JOBS";
export const FETCH_ALL_JOBS_SUCCESS = "FETCH_ALL_JOBS_SUCCESS";
export const FETCH_ALL_JOBS_FAILURE = "FETCH_ALL_JOBS_FAILURE";

export const FETCH_JOB_DETAILS = "FETCH_JOB_DETAILS";
export const FETCH_JOB_DETAILS_SUCCESS = "FETCH_JOB_DETAILS_SUCCESS";
export const FETCH_JOB_DETAILS_FAILURE = "FETCH_JOB_DETAILS_FAILURE";

export const APPLY_FOR_JOB = "APPLY_FOR_JOB";
export const APPLY_FOR_JOB_SUCCESS = "APPLY_FOR_JOB_SUCCESS";
export const APPLY_FOR_JOB_FAILURE = "APPLY_FOR_JOB_FAILURE";

export const RESET_JOB_APPLICATION_FORM = "RESET_JOB_APPLICATION_FORM";

export const SET_CURRENT_JOB_APPLICATION_ID = "SET_CURRENT_JOB_APPLICATION_ID";
export const SET_CURRENT_JOB_APPLICATION_TITLE =
  "SET_CURRENT_JOB_APPLICATION_TITLE";

export const UPLOAD_CV = "UPLOAD_CV";
export const UPLOAD_CV_SUCCESS = "UPLOAD_CV_SUCCESS";
export const UPLOAD_CV_FAILURE = "UPLOAD_CV_FAILURE";

export const RESET_RESUME = "RESET_RESUME";

export const SET_CURRENT_JOB_PAGE = "SET_CURRENT_JOB_PAGE";

export const fetchAllJobs = () => ({
  [CALL_API]: {
    types: [FETCH_ALL_JOBS, FETCH_ALL_JOBS_SUCCESS, FETCH_ALL_JOBS_FAILURE],
    endpoint: `/api/webhr/job-postings`,
    options: {
      method: "GET"
    }
  }
});

export const fetchJobDetails = (jobId, pageNumber, pageSize) => ({
  [CALL_API]: {
    types: [
      FETCH_JOB_DETAILS,
      FETCH_JOB_DETAILS_SUCCESS,
      FETCH_JOB_DETAILS_FAILURE
    ],
    endpoint: `/api/webhr/job-details/${jobId}`,
    options: {
      method: "GET"
    }
  }
});

export const applyForJob = payload => {
  return {
    [CALL_API]: {
      types: [APPLY_FOR_JOB, APPLY_FOR_JOB_SUCCESS, APPLY_FOR_JOB_FAILURE],
      endpoint: "/api/webhr/add-candidate/",
      options: {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: payload
      }
    }
  };
};

export const resetJobApplicationForm = () => ({
  type: RESET_JOB_APPLICATION_FORM
});

export const setCurrentJobApplicationId = jobId => ({
  type: SET_CURRENT_JOB_APPLICATION_ID,
  payload: jobId
});

export const setCurrentJobApplicationTitle = jobTitle => ({
  type: SET_CURRENT_JOB_APPLICATION_TITLE,
  payload: jobTitle
});

export const uploadCV = payload => {
  return {
    type: UPLOAD_CV_SUCCESS,
    payload: payload
  };
};

export const resetResume = () => ({
  type: RESET_RESUME
});

export const setCurrentJobPage = currentPage => ({
  type: SET_CURRENT_JOB_PAGE,
  payload: currentPage
});
