import { CALL_API } from '../apiMiddleware';

export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const fetchEvents = () => ({
  [CALL_API]: {
    types: [FETCH_EVENTS, FETCH_EVENTS_SUCCESS, FETCH_EVENTS_FAILURE],
    endpoint: '/api/events',
    options: {
      method: 'GET'
    }
  }
})