import { CALL_API } from "../apiMiddleware";

export const SEND_INQUIRY = "SEND_INQUIRY";
export const SEND_INQUIRY_SUCCESS = "SEND_INQUIRY_SUCCESS";
export const SEND_INQUIRY_FAILURE = "SEND_INQUIRY_FAILURE";

export const SEND_INQUIRY_PROPERTY = "SEND_INQUIRY_PROPERTY";
export const SEND_INQUIRY_PROPERTY_SUCCESS = "SEND_INQUIRY_PROPERTY_SUCCESS";
export const SEND_INQUIRY_PROPERTY_FAILURE = "SEND_INQUIRY_PROPERTY_FAILURE";

export const RESET_PROPERTY_INQUIRY_FORM = "RESET_PROPERTY_INQUIRY_FORM";
export const RESET_INQUIRY_FORM = "RESET_INQUIRY_FORM";

export const sendInquiry = ({
  name,
  email,
  phone,
  inquiry,
  client_Location,
}) => ({
  [CALL_API]: {
    types: [SEND_INQUIRY, SEND_INQUIRY_SUCCESS, SEND_INQUIRY_FAILURE],
    endpoint: "/api/inquiry",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, phone, inquiry, client_Location }),
    },
  },
});

export const sendInquiryProperty = (payload) => ({
  [CALL_API]: {
    types: [
      SEND_INQUIRY_PROPERTY,
      SEND_INQUIRY_PROPERTY_SUCCESS,
      SEND_INQUIRY_PROPERTY_FAILURE,
    ],
    endpoint: "/api/inquiry/property",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    },
  },
});

export const sendInquiryProject = (payload) => ({
  [CALL_API]: {
    types: [
      SEND_INQUIRY_PROPERTY,
      SEND_INQUIRY_PROPERTY_SUCCESS,
      SEND_INQUIRY_PROPERTY_FAILURE,
    ],
    endpoint: "/api/inquiry/project",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    },
  },
});
export const resetPropertyInquiryForm = () => ({
  type: RESET_PROPERTY_INQUIRY_FORM,
});

export const resetInquiryForm = () => ({
  type: RESET_INQUIRY_FORM,
});
