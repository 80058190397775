import {
  FETCH_ALL_AGENTS,
  FETCH_ALL_AGENTS_SUCCESS,
  FETCH_ALL_AGENTS_FAILURE
} from '../../actions/agents';

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: []
}

export const agents = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_AGENTS:
      return Object.assign({}, state, { isLoading: true });

    case FETCH_ALL_AGENTS_SUCCESS:
      return Object.assign({}, state, { isLoading: false, isDataAvailable: action.payload.data.length > 0, data: action.payload.data });

    case FETCH_ALL_AGENTS_FAILURE:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
}