import { combineReducers } from "redux";
import { home } from "./home";
import { feedbackEmail } from "./contactForm";
import { inquiryEmail } from "./inquiryForm";
import { gallery } from "./gallery";
import { cities } from "./cities";
import { areas } from "./areas";
import { search } from "./search";
import { listingDetail } from "./listingDetail";
import { agents } from "./agents";
import { comingSoonModal } from "./comingSoon";
import { wanted } from "./wanted";
import { instantValuation } from "./instantValuation";

import { groupphoto } from "./groupphoto";
import { jobs } from "./jobs";
import { projectVideos } from "./projectVideos";
import { messageEmail } from "./franchise";
import { getInTouchRed } from "./getInTouch";
import { fetchOffices } from "./offices";
// import { reducer as formReducer } from 'redux-form';
import formReducer from "./form";

export default combineReducers({
  home,
  feedbackEmail,
  inquiryEmail,
  gallery,
  cities,
  areas,
  search,
  listingDetail,
  messageEmail,
  getInTouchRed,
  agents,
  comingSoonModal,
  wanted,
  groupphoto,
  instantValuation,
  jobs,
  fetchOffices,
  projectVideos,
  form: formReducer,
});
