import fetch from "isomorphic-unfetch";
import has from "lodash/has";
import humps from "lodash-humps";

// import config from './config';

export const defaultApiSource =
  process.env.REACT_APP_ENV === "local"
    ? "https://agency21.com.pk"
    : "https://agency21.com.pk";

export const CALL_API = Symbol("CALL_API");

export const apiMiddleware = (store) => (next) => (action) => {
  if (has(action, CALL_API)) {
    const apiSource = action[CALL_API].apiSource || defaultApiSource;
    const [FETCH, SUCCESS, FAILURE] = action[CALL_API].types;
    store.dispatch({ type: FETCH });

    const api = apiSource + action[CALL_API].endpoint;

    var str = action[CALL_API].endpoint;
    var projectDetailCheck = str.includes("/api/projects/Details/");

    fetch(api, action[CALL_API].options)
      .then((resp) => {
        if (resp.status >= 400 && resp.status !== 409) {
          throw new Error("Bad response from server");
        }
        if (resp.status === 409) {
          throw 409;
        }
        return resp.json();
      })
      .then((data) => {
        if (projectDetailCheck) {
          store.dispatch({ type: SUCCESS, payload: data });
        } else {
          store.dispatch({ type: SUCCESS, payload: humps(data) });
        }
      })
      .catch((err) => {
        store.dispatch({ type: FAILURE, payload: err });
      });
  } else {
    next(action);
  }
};
