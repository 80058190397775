import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";

import {
  FETCH_LISTING_BY_ID,
  FETCH_LISTING_BY_ID_SUCCESS,
  FETCH_LISTING_BY_ID_FAILURE,
} from "../../actions/listings";

import {
  SEND_INQUIRY_PROPERTY,
  SEND_INQUIRY_PROPERTY_SUCCESS,
  SEND_INQUIRY_PROPERTY_FAILURE,
  RESET_PROPERTY_INQUIRY_FORM,
} from "../../actions/inquiryForm";

const initialState = {
  data: {},
  isLoading: false,
  isDataAvailable: false,
  inquiry: {
    sendingInquiry: false,
    inquirySentStatus: "",
  },
};

export const listingDetail = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LISTING_BY_ID:
      return Object.assign({}, state, { isLoading: true });
    case FETCH_LISTING_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        data: action.payload,
        isDataAvailable: !isEmpty(action.payload),
      });
    case FETCH_LISTING_BY_ID_FAILURE:
      return Object.assign({}, state, initialState);
    case SEND_INQUIRY_PROPERTY:
      return Object.assign({}, state, {
        inquiry: Object.assign({}, state.inquiry, { sendingInquiry: true }),
      });
    case SEND_INQUIRY_PROPERTY_SUCCESS: {
      toast.success(
        "Detail request sent. Sit back and relax while we get back to you!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return Object.assign({}, state, {
        inquiry: Object.assign({}, state.inquiry, {
          sendingInquiry: false,
          inquirySentStatus: "sent",
        }),
      });
    }
    case SEND_INQUIRY_PROPERTY_FAILURE: {
      toast.error(
        "Could not send your request at this moment, please try again later...",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return Object.assign({}, state, {
        inquiry: Object.assign({}, state.inquiry, {
          sendingInquiry: false,
          inquirySentStatus: "error",
        }),
      });
    }
    case RESET_PROPERTY_INQUIRY_FORM:
      return Object.assign({}, state, {
        inquiry: { sendingInquiry: false, inquirySentStatus: "" },
      });
    default:
      return state;
  }
};
