import remove from "lodash/remove";
import { combineReducers } from "redux";
import { toast } from "react-toastify";
import * as ActionTypes from "../../actions";
import { sendingToAssign } from "../../actions";
const initialState = {
  purpose: "Sale",
  type: "residential",
  subtype: "",
  city: {},
  cityId: {},
  selectedAreas: [],
  selectedAreasId: [],
  size: "",
  sizeUnit: "sqft",
  minPrice: "",
  maxPrice: "",
  description: "",
  phone: "",
  areaName: "",
  fullname: "",
  leadId: "",
  leadPurpose: "",
  projectId: "",
  armsProjectTypeId: "",
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_WANTED_PURPOSE:
      return Object.assign({}, state, { purpose: action.payload });

    case ActionTypes.SET_WANTED_TYPE:
      return Object.assign({}, state, { type: action.payload, subtype: "" });

    case ActionTypes.SET_WANTED_SUBTYPE:
      return Object.assign({}, state, { subtype: action.payload });

    case ActionTypes.SET_WANTED_CITY:
      return Object.assign({}, state, { city: action.payload });

    // case ActionTypes.CLEAR_WANTED_CITY:
    //   return Object.assign({}, state, { city: {} });

    case ActionTypes.CLEAR_WANTED_CITY_ID:
      return Object.assign({}, state, { cityId: action.payload });

    case ActionTypes.ADD_WANTED_AREA:
      return Object.assign({}, state, {
        selectedAreas:
          state.selectedAreas.indexOf(action.payload) === -1
            ? [...state.selectedAreas, action.payload]
            : state.selectedAreas,
      });

    case ActionTypes.ADD_WANTED_AREA_ID:
      return Object.assign({}, state, {
        selectedAreasId:
          state.selectedAreas.indexOf(action.payload) === -1
            ? [...state.selectedAreasId, action.payload]
            : state.selectedAreasId,
      });

    case ActionTypes.DELETE_WANTED_AREA:
      return Object.assign({}, state, {
        selectedAreas: remove(
          [...state.selectedAreas],
          (n) => n !== action.payload
        ),
      });

    case ActionTypes.SET_WANTED_SIZE:
      return Object.assign({}, state, { size: action.payload });

    case ActionTypes.SET_WANTED_SIZE_UNIT:
      return Object.assign({}, state, { sizeUnit: action.payload });

    case ActionTypes.SET_WANTED_MAX_PRICE:
      return Object.assign({}, state, { maxPrice: action.payload });

    case ActionTypes.SET_WANTED_DESCRIPTION:
      return Object.assign({}, state, { description: action.payload });

    case ActionTypes.SET_WANTED_PHONE:
      return Object.assign({}, state, { phone: action.payload });

    case ActionTypes.SET_WANTED_FULLNAME:
      return Object.assign({}, state, { fullname: action.payload });

    case ActionTypes.SET_WANTED_MIN_PRICE:
      return Object.assign({}, state, { minPrice: action.payload });

    case ActionTypes.SET_WANTED_PROJECT_ID:
      return Object.assign({}, state, { projectId: action.payload });

    case ActionTypes.SET_WANTED_PROJECT_TYPE:
      return Object.assign({}, state, { armsProjectTypeId: action.payload });

    case ActionTypes.SET_INTERMEDIARY_AREANAME:
      return Object.assign({}, state, { areaName: action.payload });

    case ActionTypes.RESET_WANTED_SPEC_FORM:
    case ActionTypes.REQUEST_SUBMIT_WANTED_FORM_SUCCESS:
      return Object.assign(
        {},
        state,
        { leadId: action.payload.id, leadPurpose: action.payload.purpose },
        initialState
      );
    // return Object.assign({}, state, initialState);
    case ActionTypes.SENDING_TO_ASSIGN:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
};

const submitInProgress = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_SUBMIT_WANTED_FORM:
      return true;
    case ActionTypes.REQUEST_SUBMIT_WANTED_FORM_FAILURE: {
      toast.error("Could not submit your inquiry, please try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    case ActionTypes.REQUEST_SUBMIT_WANTED_FORM_SUCCESS: {
      toast.success(
        "You query has been submitted successfully, our representative will get in touch with you soon.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return false;
    }
    case ActionTypes.SENDING_TO_ASSIGN: {
      // toast.success('.')
      return false;
    }
    default:
      return state;
  }
};

export const wanted = combineReducers({
  data,
  submitInProgress,
});
