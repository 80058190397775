const env = process.env.NODE_ENV || "development";

const config = {
  development: {
// apiPath: 'https://api.ssr.graana.rocks',
    apiPath: "https://api.dev.graana.rocks",
    s3URL: "https://images.graana.com",
// apiPath: 'https://www.graana.com', // for production
// apiPath: 'http://localhost:3000',
    agencyApiPath: "https://dev.agency21.rocks",
    domain: "localhost",
    revivePath: "https://revive.graana.rocks",
    lockScreen: true,
    analytics: "UA-112708340-1",
    firebaseConfigCounter: {
      apiKey: "AIzaSyArLlFdASP09iNmLoeQJzuEmatacftQG7k",
      authDomain: "counter-a85f7.firebaseapp.com",
      databaseURL: "https://counter-a85f7.firebaseio.com",
      projectId: "counter-a85f7",
      storageBucket: "counter-a85f7.appspot.com",
      messagingSenderId: "141073216855",
      appId: "1:141073216855:web:c7a363462f0434c8a97a12",
      name: "counter"
    },

    websitePath: "https://ssr.graana.rocks",
    firebaseConfig: {
      apiKey: "AIzaSyBcMF6jv0j0EY82JC9XW0jKMu4o7fRDKrg",
      authDomain: "graana-push-notification-e4375.firebaseapp.com",
      databaseURL: "https://graana-push-notification-e4375.firebaseio.com",
      projectId: "graana-push-notification-e4375",
      storageBucket: "graana-push-notification-e4375.appspot.com",
      messagingSenderId: "372529293613",
      appId: "1:372529293613:web:3d756e12be4475b1c74ba4",
      measurementId: "G-8CRWYW95PT"
    },
    eGateway: {
      scriptUrl: "https://test-mcbpk.mtf.gateway.mastercard.com/checkout/version/52/checkout.js",
      merchantId: "472210039829"
    },
    algolia: {
      app_id: "0UAX0KCC58",
      api_key: "40881d425a84662150e6a26985c71b5a"
    }
  },
  production: {
    websitePath: "https://www.graana.com",
// apiPath: 'https://api.ssr.graana.rocks',
// apiPath: 'http://localhost:3001',
    apiPath: "https://www.graana.com", // for production
    agencyApiPath: "https://www.agency21.com.pk/", // for production
// domain: '.realtor.agency21.rocks/api/user',
    domain: ".graana.rocks",
    s3URL: "https://images.graana.com",
    revivePath: "https://revive.graana.rocks",
    lockScreen: true,
    analytics: "UA-112708340-1",
    algolia: {
      app_id: "0UAX0KCC58",
      api_key: "40881d425a84662150e6a26985c71b5a"
    }
  },
  domain: ".graana.rocks"
};

module.exports = config[env];
