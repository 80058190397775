import { toast } from "react-toastify";

import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_FAILURE,
  SEND_FEEDBACK_SUCCESS,
  RESET_FORM_STATE,
} from "../../actions/contactForm";

const initialState = {
  sendingFeedback: false,
  feedbackSentStatus: "",
};

export const feedbackEmail = (state = initialState, action) => {
  switch (action.type) {
    case SEND_FEEDBACK:
      return Object.assign({}, state, { sendingFeedback: true });

    case SEND_FEEDBACK_SUCCESS: {
      toast.success("Thank you for your valuable feedback!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        sendingFeedback: false,
        feedbackSentStatus: "sent",
      });
    }

    case SEND_FEEDBACK_FAILURE: {
      toast.error("Something went wrong, Please try later...", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        sendingFeedback: false,
        feedbackSent: "error",
      });
    }

    case RESET_FORM_STATE:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
};
