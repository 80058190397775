import { CALL_API } from '../apiMiddleware';

export const FETCH_ALL_GROUPPHOTO = 'FETCH_ALL_GROUPPHOTO';
export const FETCH_ALL_GROUPPHOTO_SUCCESS = 'FETCH_ALL_GROUPPHOTO_SUCCESS';
export const FETCH_ALL_GROUPPHOTO_FAILURE = 'FETCH_ALL_GROUPPHOTO_FAILURE';

export const fetchAllGroupPhoto = () => ({
  [CALL_API]: {
    types: [FETCH_ALL_GROUPPHOTO, FETCH_ALL_GROUPPHOTO_SUCCESS, FETCH_ALL_GROUPPHOTO_FAILURE],
    endpoint: `/api/groupPhoto`,
    options: {
      method: 'GET'
    }
  }
})