import { CALL_API } from "../apiMiddleware";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILURE = "SEND_MESSAGE_FAILURE";
export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";
export const RESET_FORM_STATE = "RESET_FORM_STATE";

// export const resetFormState = () => ({
//   type: RESET_FORM_STATE,
// });

export const sendMessage = ({
  first_name,
  // last_name,
  email,
  phone,
  city,
  city_location,
  reference,
  message,
  qrcode,
}) => ({
  [CALL_API]: {
    types: [SEND_MESSAGE, SEND_MESSAGE_SUCCESS, SEND_MESSAGE_FAILURE],
    endpoint: "/api/franchise/addFranchise",
    // endpoint:"api/dubaiEvent",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        first_name,
        // last_name,
        email,
        phone,
        city,
        city_location,
        reference,
        message,
        qrcode,
      }),
    },
  },
});
// export const sendEmail = ({
//   name,
//   phone,
//   email,
//   company,
//   city,
// }) => ({
//   [CALL_API]: {
//     types: [SEND_EMAIL, SEND_EMAIL_SUCCESS,SEND_EMAIL_FAILURE ],
//     endpoint: "api/dubaiEvent",
//     options: {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         name,
//         phone,
//         email,
//         company,
//         city,
//       }),
//     },
//   },
// });
