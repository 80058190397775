import {
    FETCH_EVENTS,
    FETCH_EVENTS_SUCCESS,
    FETCH_EVENTS_FAILURE
  } from '../../actions/gallery';
  
  const initialState = {
    isLoading: false,
    isDataAvailable: false,
    data: []
  }
  
  export const events = (state = initialState, action) => {
    switch(action.type){
      case FETCH_EVENTS:
        return Object.assign({}, state, {isLoading: true});
  
      case FETCH_EVENTS_SUCCESS:
        return Object.assign({}, state, {isLoading: false, isDataAvailable: action.payload.length > 0, data: action.payload });
      
      case FETCH_EVENTS_FAILURE:
        return Object.assign({}, state, initialState);
  
      default:
        return state;
    }
  }