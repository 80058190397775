import React from 'react';

import LoadingState from '../LoadingState';
import './LoadingLoadable.scss';

const LoadingLoadable = () => {
  // console.log("here in loadbales===========>")
  return(
    <div className="loading-loadable-root">
      <LoadingState />
    </div>
  );
}

export default LoadingLoadable;