export const routes = [
  {
    path: "/about",
    label: "about",
    absolute: false,
    id: 1,
  },
  {
    path: "/search?purpose=sale&cityId=1", //search?purpose=rent"
    label: "properties",
    absolute: false,
    id: 2,
  },
  {
    path: "/",
    label: "projects",
    absolute: false,
    id: 3,
  },
  // {
  //   path: "/team",
  //   label: "team",
  //   absolute: false,
  //   id: 4,
  // },
  {
    path: "/gallery",
    label: "gallery",
    absolute: false,
    id: 5,
  },
  {
    path: "/blog",
    absolute: false,
    label: "blog",
    id: 6,
  },
  {
    path: "/careers",
    label: "careers",
    absolute: false,
    id: 7,
  },
  {
    path: "/contact",
    label: "contact",
    absolute: false,
    id: 8,
  },
  // {
  //   path: "/get-agency21-franchise",
  //   label: "Franchise",
  //   absolute: false,
  //   id: 9,
  // },
  // {
  //   path: "/iso",
  //   label: "ISO",
  //   id: 10
  // }
];
