import { CALL_API } from '../apiMiddleware';

export const FETCH_AGENTS = 'FETCH_AGENTS';
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_FAILURE = 'FETCH_AGENTS_FAILURE';

export const FETCH_LISTINGS = 'FETCH_LISTINGS';
export const FETCH_LISTINGS_SUCCESS = 'FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_FAILURE = 'FETCH_LISTINGS_FAILURE';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJETS_FAILURE';

export const FETCH_COVERS = 'FETCH_COVERS';
export const FETCH_COVERS_SUCCESS = 'FETCH_COVERS_SUCCESS';
export const FETCH_COVERS_FAILURE = 'FETCH_COVERS_FAILURE';

export const INCREMENT_LISTINGS_CURRENT_PAGE = 'INCREMENT_LISTINGS_CURRENT_PAGE';
export const DECREMENT_LISTINGS_CURRENT_PAGE = 'DECREMENT_LISTINGS_CURRENT_PAGE';

export const INCREMENT_PROJECTS_CURRENT_PAGE = 'INCREMENT_PROJECTS_CURRENT_PAGE';
export const DECREMENT_PROJECTS_CURRENT_PAGE = 'DECREMENT_PROJECTS_CURRENT_PAGE';

export const SET_INITIAL_BOTTOM_LISTINGS_VISIBLE_DATA = 'SET_INITIAL_BOTTOM_LISTINGS_VISIBLE_DATA';
export const SET_INITIAL_PROJECTS_VISIBLE_DATA = 'SET_INITIAL_PROJECTS_VISIBLE_DATA';
export const SET_INITIAL_TOP_LISTINGS_VISIBLE_DATA = 'SET_INITIAL_TOP_LISTINGS_VISIBLE_DATA';

export const FETCH_TESTIMONIALS = 'FETCH_TESTIMONIALS'
export const FETCH_TESTIMONIALS_SUCCESS = 'FETCH_TESTIMONIALS_SUCCESS'
export const FETCH_TESTIMONIALS_FAILURE = 'FETCH_TESTIMONIALS_FAILURE'

export const fetchTestimonials = () => ({
  [CALL_API]: {
    types: [FETCH_TESTIMONIALS, FETCH_TESTIMONIALS_SUCCESS, FETCH_TESTIMONIALS_FAILURE],
    endpoint: '/api/testimonials',
    options: {
      method: 'GET'
    }
  }
})

export const fetchAgents = () => ({
  [CALL_API]: {
    types: [FETCH_AGENTS, FETCH_AGENTS_SUCCESS, FETCH_AGENTS_FAILURE],
    endpoint: '/api/agents?count=3',
    options: {
      method: 'GET'
    }
  }
})

export const fetchListings = () => ({
  [CALL_API]: {
    types: [FETCH_LISTINGS, FETCH_LISTINGS_SUCCESS, FETCH_LISTINGS_FAILURE],
    endpoint: '/api/listings',
    options: {
      method: 'GET',
    }
  }
})

export const fetchProjects = () => ({
  [CALL_API]: {
    types: [FETCH_PROJECTS, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE],
    endpoint: '/api/projects',
    options: {
      method: 'GET',
    }
  }
})

export const fetchProjectsDetail = (id) => ({
  [CALL_API]: {
    types: [FETCH_PROJECTS, FETCH_PROJECTS_SUCCESS, FETCH_PROJECTS_FAILURE],
    endpoint: `/api/projects/Details/${id}`,
    options: {
      method: 'GET',
    }
  }
})

export const fetchCovers = () => ({
  [CALL_API]: {
    types: [FETCH_COVERS, FETCH_COVERS_SUCCESS, FETCH_COVERS_FAILURE],
    endpoint: '/api/covers',
    options: {
      method: 'GET'
    }
  }
})



export const incrementListingsCurrentPage = () => ({ type: INCREMENT_LISTINGS_CURRENT_PAGE });
export const decrementListingsCurrentPage = () => ({ type: DECREMENT_LISTINGS_CURRENT_PAGE });

export const incrementProjectsCurrentPage = () => ({ type: INCREMENT_PROJECTS_CURRENT_PAGE });
export const decrementProjectsCurrentPage = () => ({ type: DECREMENT_PROJECTS_CURRENT_PAGE });

export const setInitialBottomListingsVisibleData = (payload) => ({
  type: SET_INITIAL_BOTTOM_LISTINGS_VISIBLE_DATA,
  payload
});

export const setInitialProjectsVisibleData = (payload) => ({
  type: SET_INITIAL_PROJECTS_VISIBLE_DATA,
  payload
});

export const setInitialTopListingsVisibleData = (payload) => ({
  type: SET_INITIAL_TOP_LISTINGS_VISIBLE_DATA,
  payload
});