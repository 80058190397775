import React from "react";
import "./CustomFormField.scss";

const CustomFormField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => {
  return (
    <>
      <div className="customer-form-field">
        {type === "textarea" ? (
          <textarea {...input} placeholder={label} type={type} />
        ) : (
          <input
            {...input}
            placeholder={label}
            minLength={label === "Phone Number" ? 10 : false}
            maxLength={label === "Phone Number" ? 15 : false}
            type={type}
          />
        )}
        {touched &&
          ((error && (
            <div className="error-div">
              <div className="error-container">{error}</div>
            </div>
          )) ||
            (warning && <span>{warning}</span>))}
      </div>
    </>
  );
};

export default CustomFormField;
