import { CALL_API } from '../apiMiddleware';

export const SET_VALUATION_PURPOSE = 'SET_VALUATION_PURPOSE';
export const SET_VALUATION_CITY = 'SET_VALUATION_CITY';
export const SET_VALUATION_AREA = 'SET_VALUATION_AREA';
export const REQUEST_CREATE_VALUATION_QUERY = 'REQUEST_CREATE_VALUATION_QUERY';
export const REQUEST_CREATE_VALUATION_QUERY_SUCCESS = 'REQUEST_CREATE_VALUATION_QUERY_SUCCESS';
export const REQUEST_CREATE_VALUATION_QUERY_FAILURE = 'REQUEST_CREATE_VALUATION_QUERY_FAILURE';
export const RESET_VALUATION_FORM = 'RESET_VALUATION_FORM';

export const setValuationCity = (payload) => ({
  type: SET_VALUATION_CITY,
  payload
})

export const setValuationPurpose = (payload) => ({
  type: SET_VALUATION_PURPOSE,
  payload
})

export const setValuationArea = (payload) => ({
  type: SET_VALUATION_AREA,
  payload
})

export const resetValuationForm = () => ({
  type: RESET_VALUATION_FORM
})

export const requestCreateValuationQuery = (payload) => ({
  [CALL_API]: {
    types: [REQUEST_CREATE_VALUATION_QUERY, REQUEST_CREATE_VALUATION_QUERY_SUCCESS, REQUEST_CREATE_VALUATION_QUERY_FAILURE],
    endpoint: `/api/valuation`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }
  }
})