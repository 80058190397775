import {
  FETCH_ALL_OFFICES,
  FETCH_ALL_OFFICES_SUCCESS,
  FETCH_ALL_OFFICES_FAILURE,
} from "../../actions/offices";

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: [],
};

export const fetchOffices = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_OFFICES:
      return Object.assign({}, state, { isLoading: true });

    case FETCH_ALL_OFFICES_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        isDataAvailable: action.payload.length > 0,
        data: action.payload,
      });
    }

    case FETCH_ALL_OFFICES_FAILURE: {
      return Object.assign({}, state, initialState);
    }

    default:
      return state;
  }
};
