import {
  FETCH_LISTINGS,
  FETCH_LISTINGS_SUCCESS,
  FETCH_LISTINGS_FAILURE,
  INCREMENT_LISTINGS_CURRENT_PAGE,
  DECREMENT_LISTINGS_CURRENT_PAGE,
  SET_INITIAL_BOTTOM_LISTINGS_VISIBLE_DATA,
  SET_INITIAL_TOP_LISTINGS_VISIBLE_DATA
} from '../../actions/home';
import { shiftVisibleArrayToRight, shiftVisibleArrayToLeft } from '../../utils';

const initialState = {
  data: {
    data: []
  },
  isLoading: false,
  isDataAvailable: false,
  visibleData: {
    startIndex: 0,
    endIndex: 4,
    perPage: 4,
  },
  visibleDataBottom: {
    startIndex: 0,
    endIndex: 6,
    perPage: 6,
  },

}

export const listings = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LISTINGS:
      return Object.assign({}, state, { isLoading: true, isDataAvailable: false, data: { data: [] } });

    case FETCH_LISTINGS_SUCCESS: {
      return Object.assign(
        {},
        state,
        {
          isLoading: false,
          isDataAvailable: action.payload.data.length > 0,
          data: action.payload,
        }
      );
    }

    case FETCH_LISTINGS_FAILURE:
      return Object.assign({}, state, { isLoading: false, isDataAvailable: false, data: { data: [] } });

    case SET_INITIAL_BOTTOM_LISTINGS_VISIBLE_DATA:
      return Object.assign({}, state, { visibleDataBottom: action.payload });

    case SET_INITIAL_TOP_LISTINGS_VISIBLE_DATA:
      return Object.assign({}, state, { visibleData: action.payload });

    case INCREMENT_LISTINGS_CURRENT_PAGE: {
      const { data: { data }, visibleData: { startIndex, endIndex, perPage }, visibleDataBottom } = state;
      const [nextStartIndex, nextEndIndex] = shiftVisibleArrayToRight(data.length, startIndex, endIndex, perPage);
      const [nsiBottom, neiBottom] = shiftVisibleArrayToRight(data.length, visibleDataBottom.startIndex, visibleDataBottom.endIndex, visibleDataBottom.perPage)

      return Object.assign({}, state, {
        visibleData: Object.assign({}, state.visibleData, { startIndex: nextStartIndex, endIndex: nextEndIndex }),
        visibleDataBottom: Object.assign({}, state.visibleDataBottom, { startIndex: nsiBottom, endIndex: neiBottom })
      }
      )
    }

    case DECREMENT_LISTINGS_CURRENT_PAGE: {
      const { data: { data }, visibleData: { startIndex, endIndex, perPage }, visibleDataBottom } = state;
      const [nextStartIndex, nextEndIndex] = shiftVisibleArrayToLeft(data.length, startIndex, endIndex, perPage);
      const [nsiBottom, neiBottom] = shiftVisibleArrayToLeft(data.length, visibleDataBottom.startIndex, visibleDataBottom.endIndex, visibleDataBottom.perPage)

      return Object.assign({}, state, {
        visibleData: Object.assign({}, state.visibleData, { startIndex: nextStartIndex, endIndex: nextEndIndex }),
        visibleDataBottom: Object.assign({}, state.visibleDataBottom, { startIndex: nsiBottom, endIndex: neiBottom })
      }
      )
    }
    default:
      return state;
  }
}
