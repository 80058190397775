import {
    FETCH_EVENT_DETAIL,
    FETCH_EVENT_DETAIL_SUCCESS,
    FETCH_EVENT_DETAIL_FAILURE,
    SET_SELECTED_TAB, 
    RESET_GALLERY_ITEM_DETAIL_PAGE,
  } from '../../actions/galleryItemDetail';
  
  import { ALL } from '../../config/constants';
  
  const initialState = {
    isLoading: false,
    isDataAvailable: false,
    data: {},
    selectedTab: ALL,
  }
  
  export const eventDetail = (state=initialState, action) => {
    switch(action.type){
      case FETCH_EVENT_DETAIL:
        return Object.assign({}, state, {isLoading: true});
  
      case FETCH_EVENT_DETAIL_SUCCESS:
        return Object.assign({}, state, {isLoading: false, isDataAvailable: action.payload.eventMedia.length > 0, data: action.payload})
  
      case FETCH_EVENT_DETAIL_FAILURE:
        return Object.assign({}, state, initialState);
  
      case SET_SELECTED_TAB:
        return Object.assign({}, state, { selectedTab: action.payload });
  
      case RESET_GALLERY_ITEM_DETAIL_PAGE:
        return Object.assign({}, state, initialState);
  
      default:
        return state;
    }
  }