import * as ActionTypes from '../../actions';

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: []
}

export const covers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COVERS:
      return Object.assign({}, state, { isLoading: true });

    case ActionTypes.FETCH_COVERS_SUCCESS:
      return Object.assign({}, state, { isLoading: false, isDataAvailable: action.payload.length > 0, data: action.payload });

    case ActionTypes.FETCH_COVERS_FAILURE:
      return Object.assign({}, state, initialState)
    default:
      return state;
  }
}