import { toast } from "react-toastify";
import {
  SEND_INQUIRY,
  SEND_INQUIRY_SUCCESS,
  SEND_INQUIRY_FAILURE,
  RESET_INQUIRY_FORM,
} from "../../actions/inquiryForm";

const initialState = {
  sendingInquiry: false,
  inquirySentStatus: "",
};

export const inquiryEmail = (state = initialState, action) => {
  switch (action.type) {
    case SEND_INQUIRY:
      return Object.assign({}, state, { sendingInquiry: true });

    case SEND_INQUIRY_SUCCESS: {
      toast.success("Inquiry Sent Successfuly!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        sendingInquiry: false,
        inquirySentStatus: "sent",
      });
    }

    case SEND_INQUIRY_FAILURE: {
      toast.error(
        "Could not send inquiry at this moment, please try again later...",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return Object.assign({}, state, {
        sendingInquiry: false,
        inquirySentStatus: "error",
      });
    }

    case RESET_INQUIRY_FORM: {
      return Object.assign({}, state, initialState);
    }

    default:
      return state;
  }
};
