import { CALL_API } from '../apiMiddleware';

export const FETCH_EVENT_DETAIL = 'FETCH_EVENT_DETAIL';
export const FETCH_EVENT_DETAIL_SUCCESS = 'FETCH_EVENT_DETAIL_SUCCESS';
export const FETCH_EVENT_DETAIL_FAILURE = 'FETCH_EVENT_DETAIL_FAILURE';

export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const RESET_GALLERY_ITEM_DETAIL_PAGE = 'RESET_GALLERY_ITEM_DETAIL_PAGE';

export const fetchEventDetail = (eventId) => ({
  [CALL_API]: {
    types: [FETCH_EVENT_DETAIL, FETCH_EVENT_DETAIL_SUCCESS, FETCH_EVENT_DETAIL_FAILURE],
    endpoint: `/api/events/${eventId}`,
    options: {
      method: 'GET'
    }
  }
});

export const setSelectedTab = (payload) => ({type: SET_SELECTED_TAB, payload});
export const resetGalleryItemDetailPage = () => ({
  type: RESET_GALLERY_ITEM_DETAIL_PAGE,
})