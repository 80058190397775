import { CALL_API } from "../apiMiddleware";

export const FETCH_CITY_AREAS = "FETCH_CITY_AREAS";
export const FETCH_CITY_AREAS_SUCCESS = "FETCH_CITY_AREAS_SUCCESS";
export const FETCH_CITY_AREAS_FAILURE = "FETCH_CITY_AREAS_FAILURE";

export const fetchCityAreas = (city_id) => ({
  [CALL_API]: {
    types: [
      FETCH_CITY_AREAS,
      FETCH_CITY_AREAS_SUCCESS,
      FETCH_CITY_AREAS_FAILURE,
    ],
    endpoint: `/api/area?cityId=${city_id}`,
    options: {
      method: "GET",
    },
  },
});
