import { TOGGLE_COMING_SOON_MODAL } from '../actions/comingSoon';

const initialState = {
  isOpen: false
}
export const comingSoonModal = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_COMING_SOON_MODAL:
      return Object.assign({}, state, { isOpen: !state.isOpen });

    default:
      return state;
  }
}