import { CALL_API } from '../apiMiddleware';

export const FETCH_ALL_AGENTS = 'FETCH_ALL_AGENTS';
export const FETCH_ALL_AGENTS_SUCCESS = 'FETCH_ALL_AGENTS_SUCCESS';
export const FETCH_ALL_AGENTS_FAILURE = 'FETCH_ALL_AGENTS_FAILURE';

export const fetchAllAgents = () => ({
  [CALL_API]: {
    types: [FETCH_ALL_AGENTS, FETCH_ALL_AGENTS_SUCCESS, FETCH_ALL_AGENTS_FAILURE],
    endpoint: `/api/agents?per_page=1000&current_page=1`,
    options: {
      method: 'GET'
    }
  }
})