import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  faStroopwafel,
  faAngleDown,
  faWindowClose,
  faTimes,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

import Layout from "./components/Layout";
import Home from "./views/Home";
import Search from "./views/Search";
import About from "./views/About";
import Contact from "./views/Contact";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Terms from "./views/Terms";
import Gallery from "./views/Gallery";
import GalleryItemDetail from "./views/GalleryItemDetail";
import ListingDetail from "./views/ListingDetail";
import Agents from "./views/Agents";
import Wanted from "./views/Wanted";
import Route404 from "./components/Route404";
import "./App.scss";
import { fetchCities } from "./actions/cities";
import InstantValuation from "./views/InstantValuation";
// import ProjectPage from "./views/ProjectPage";
import LoadingLoadable from "./components/LoadingLoadable";
import JobListingsPage from "./views/JobsListingPage";
import JobDescriptionPage from "./views/JobDescription";
import ISO from "./views/ISO";
import Franchise from "./views/Franchise";
import DubaiEvent from "./views/DubaiEvent";
const ProjectPage = React.lazy(() => import("./views/ProjectPage/ProjectPage"));

library.add(faStroopwafel, faAngleDown, faWindowClose, faTimes, faFilter);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { showHeader: true, showFooter: true };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(fetchCities());
  }
  componentWillUnmount() {
    this.setState({ showHeader: true, showFooter: true });
  }
  hideHeaderAndFooter = () => {
    this.setState({ showHeader: false, showFooter: false });
  };
  render() {
    return (
      <Layout data={this.props} showHeader={this.state.showHeader}>
        <Switch>
          <React.Suspense fallback={<LoadingLoadable />}>
            <Route exact path="/" component={Home} />
            <Route path="/search" component={Search} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            {
              // <Route exact path="/team" component={Agents} />
            }
            <Route path="/terms" component={Terms} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/wanted" component={Wanted} />
            <Route path="/gallery/:id" component={GalleryItemDetail} />
            <Route path="/listing/:id" component={ListingDetail} />
            <Route path="/valuation" component={InstantValuation} />
            <Route
              path="/project/:id/:slug"
              component={() => <ProjectPage data={window.location} />}
            />
            <Route path="/careers" component={JobListingsPage} />
            <Route path="/job-description/:id" component={JobDescriptionPage} />
            <Route path="/get-agency21-franchise" component={Franchise} />
            <Route path="/dubai-re-event" component={DubaiEvent} />
            <Route path="/iso" component={ISO} />

            <Route
              path="/blog"
              component={() => {
                window.location.href = "https://www.agency21.com.pk/blog/";
                return null;
              }}
            />
          </React.Suspense>
          <Route component={Route404} />
        </Switch>
      </Layout>
    );
  }
}

export default withRouter(connect()(App));
