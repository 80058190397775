import {
  FETCH_CITY_AREAS,
  FETCH_CITY_AREAS_SUCCESS,
  FETCH_CITY_AREAS_FAILURE,
} from "../../actions/areas";

const initialState = {
  data: [],
  isLoading: false,
  isDataAvailable: false,
};

export const areas = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CITY_AREAS:
      return Object.assign({}, state, { isLoading: true });
    case FETCH_CITY_AREAS_SUCCESS:
    return Object.assign({}, state, {
      isLoading: false,
      isDataAvailable: action.payload.length > 0,
      data: action.payload
    });
    case FETCH_CITY_AREAS_FAILURE:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
};
