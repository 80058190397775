import { CALL_API } from '../apiMiddleware';

export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';

export const RESET_FORM_STATE = 'RESET_FORM_STATE';

export const resetFormState = () => ({
  type: RESET_FORM_STATE,
});

export const sendFeedback = ({ name, email, phone, feedback }) => ({
  [CALL_API]: {
    types: [SEND_FEEDBACK, SEND_FEEDBACK_SUCCESS, SEND_FEEDBACK_FAILURE],
    endpoint: '/api/contact',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name, email, phone, feedback})
    }
  }
})