import { CALL_API } from "../apiMiddleware";

export const FETCH_ALL_OFFICES = "FETCH_ALL_OFFICES";
export const FETCH_ALL_OFFICES_SUCCESS = "FETCH_ALL_OFFICES_SUCCESS";
export const FETCH_ALL_OFFICES_FAILURE = "FETCH_ALL_OFFICES_FAILURE"

export const allOffices = () => ({
  [CALL_API]: {
    types: [FETCH_ALL_OFFICES, FETCH_ALL_OFFICES_SUCCESS, FETCH_ALL_OFFICES_FAILURE],
    endpoint: "/api/offices/",
    options: {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  },
});
