import config from "../config";
import { generateImageUrl } from "../helpers";
export const shiftVisibleArrayToRight = (
  total,
  startIndex,
  endIndex,
  perPage
) => {
  if (endIndex > total) {
    return [startIndex, endIndex];
  }
  const nextStartIndex = endIndex;
  const temp = endIndex + perPage;
  const nextEndIndex = temp > total ? temp - total : temp;

  return [nextStartIndex, nextEndIndex];
};

export const shiftVisibleArrayToLeft = (
  total,
  startIndex,
  endIndex,
  perPage
) => {
  if (endIndex > total) {
    return [startIndex, endIndex];
  }
  const nextEndIndex = startIndex === 0 ? total : startIndex;
  const temp = nextEndIndex - perPage;
  const nextStartIndex = temp < 0 ? total - Math.abs(temp) : temp;

  return [nextStartIndex, nextEndIndex];
};

export const parseQueryString = qs => {
  let q = qs.substring(1, qs.length).split("&");
  let params = {};
  q.forEach(item => {
    let values = item.split("=");
    params[values[0]] = values[1];
  });
  return params;
};

export const calculateGraanaRedirectUrl = (domain, listing) => {
  const { city, area, title, id } = listing;
  const rx = new RegExp("[ ]{1,}");
  return `${domain}/property/${city.name.trim().toLowerCase()}-${area.name
    .trim()
    .toLowerCase()
    .split(rx)
    .join("-")
    .split("/")
    .join("")}-${title
    .trim()
    .toLowerCase()
    .split(rx)
    .join("-")}-${id}`;
};

export const curriedCalculateGraanaRedirectUrl = domain => listing => {
  const { city, area, title, id } = listing;
  const rx = new RegExp("[ ]{1,}");
  return `${domain}/property/${city.name.trim().toLowerCase()}-${area.name
    .trim()
    .toLowerCase()
    .split(rx)
    .join("-")
    .split("/")
    .join("")}-${title
    .trim()
    .toLowerCase()
    .split(rx)
    .join("-")}-${id}`;
};

export const makeQueryString = (prevParams, newParams) => {
  const latestParams = Object.assign({}, prevParams, newParams);
  const arr = [];

  for (let param in latestParams) {
    arr.push(`${param}=${latestParams[param]}`);
  }

  const qs = arr.join("&");
  return `/search?${qs}`;
};

// function replaceAll(str, find, replace) {
//   var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
//   return str.replace(new RegExp(escapedFind, 'g'), replace);
// }

export const addOptimizationParameters = (url, options) => {
return `${generateImageUrl(url, '840')}`
  const newURL = url.replaceAll("/o_40,h_0.15,l_gwm,fl_relative", "");
  const [firstUrlFragment, secondUrlFragment] = newURL.split("/upload/");
  const { /* width, */ height, quality, c_fit, show_watermark } = options;
  const watermark = "l_awm,fl_relative,o_40,h_0.15/";
  const firstUrlFragmentHttps = firstUrlFragment.startsWith("https")
    ? firstUrlFragment
    : firstUrlFragment.replace("http", "https");
  return [
    firstUrlFragmentHttps,
    [
      `h_${height},f_auto,q_auto:${quality || "low"},${c_fit ? "c_fit" : ""}`,
      secondUrlFragment
    ].join("/")
  ].join(`/upload/${show_watermark ? watermark : ""}`);
};

export const parseLatLongString = str => {
  const latLongStrings = str.match(/[0-9]+.[0-9]+,\s*[0-9]+.[0-9]+/g);
  return latLongStrings.map(item => {
    const [lat, long] = item.split(",");
    return { lat, long };
  });
};

function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const formatPrice = price => {
  if (price / 10000000 >= 1) {
    const r = price / 10000000;
    return isFloat(r) ? `${r.toFixed(2)} Crore` : `${r} Crore`;
  } else if (price / 100000 >= 1) {
    const r = price / 100000;
    return isFloat(r) ? `${r.toFixed(2)} Lac` : `${r} Lac`;
  } else if (price === 0) {
    return `Call`;
  } else {
    return `${price}`;
  }
};

const placeHolderListingImges = [
  `/agency21/images/original/properties/gallery_01/1542191977_jpg`,
  `/agency21/images/original/properties/gallery_02/1542191938_jpg`,
  `/agency21/images/original/properties/gallery_03/1542191894_jpg`,
  `/agency21/images/original/properties/gallery_04/1542191892_jpg`,
  `/agency21/images/original/properties/gallery_05/1542191889_jpg`,
  `/agency21/images/original/properties/gallery_06/1542191883_jpg`
];

export const getListingPlaceholderImage = () => {
  let image =
    placeHolderListingImges[
      Math.floor(Math.random() * placeHolderListingImges.length)
    ];
  return image;
};

export const removeDuplicates = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);

  return unique;
};

export const getScrollInfo = () => {
  const supportPageOffset = window.pageXOffset !== undefined;
  const isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
  const scroll = {
    x: supportPageOffset
      ? window.pageXOffset
      : isCSS1Compat
      ? document.documentElement.scrollLeft
      : document.body.scrollLeft,
    y: supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop
  };
  return scroll;
};

export const createMarkup = markup => {
  return { __html: markup };
};
