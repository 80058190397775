import React from "react";
import { Modal } from "reactstrap";
import { Field, reduxForm } from "redux-form";

import CustomFormField from "../../components/CustomFormField";
import { isRequired, isNumber, isEmail } from "../../utils";
import "./InquiryForm.scss";

const InquiryFormInner = ({
  isInquiryModalOpen,
  toggleInquiryModal,
  handleSubmit,
  invalid,
  inquiry: { inquirySentStatus, sendingInquiry },
  onModalClosed,
}) => {
  let buttonText = "Submit Inquiry";
  if (sendingInquiry) {
    buttonText = "Sending Inquiry...";
  }
  if (inquirySentStatus === "sent") {
    buttonText = "Sent!";
  }
  if (inquirySentStatus === "error") {
    buttonText = "Something went wrong!";
  }
  return (
    <Modal
      isOpen={isInquiryModalOpen}
      toggle={toggleInquiryModal}
      onClosed={onModalClosed}
    >
      <div className="inquiry-modal-body modal-size" id="inquirymodal">
        <div className="cross-icon" onClick={toggleInquiryModal} />
        <div className="inquiry-head">
          <h1>Send Inquiry</h1>
        </div>
        <form onSubmit={handleSubmit} className="form-inquiry">
          <Field
            name="name"
            type="text"
            label="Name"
            validate={[isRequired]}
            component={CustomFormField}
          />
          <Field
            name="email"
            type="email"
            label="Email Address"
            validate={[isRequired, isEmail]}
            component={CustomFormField}
          />
          <Field
            name="phone"
            type="text"
            label="Phone Number"
            validate={[isRequired, isNumber]}
            component={CustomFormField}
          />
          <Field
            name="client_Location"
            type="text"
            label="Client Location"
            validate={[isRequired]}
            component={CustomFormField}
            a
          />
          <Field
            name="inquiry"
            label="Inquiry"
            type="textarea"
            validate={[isRequired]}
            component={CustomFormField}
          />
          <button type="submit" disabled={invalid}>
            {buttonText}
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default reduxForm({
  form: "inquiry",
})(InquiryFormInner);
