import { s3URL } from "../helpers";

export const ALL = "ALL";
export const VIDEOS = "VIDEOS";
export const PHOTOS = "PHOTOS";
export const IMAGE = "image";
export const VIDEO = "video";


export const SIZE_UNITS = [
  { label: "Ft²", value: "sqft" },
  { label: "Yd²", value: "sqyd" },
  { label: "M²", value: "sqm" },
  { label: "Kanal", value: "kanal" },
  { label: "Marla", value: "marla" }
];
export const types = [
  {
    title: "All Types",
    value: "all",
    options: [
      {
        label: "All Types",
        value: "all"
      }
    ]
  },
  {
    title: "Residential",
    value: "residential",
    options: [
      { label: "All Residential", value: "residential" },
      { label: "House", value: "house" },
      { label: "Apartment", value: "apartment" },
      { label: "Upper Portion", value: "upper portion" },
      { label: "Lower Portion", value: "lower portion" },
      { label: "Farm House", value: "farm house" },
      { label: "Room", value: "room" },
      { label: "Penthouse", value: "penthouse" },
      { label: "Hotel Suites", value: "hotel suites" },
      { label: "Basement", value: "basement" },
      { label: "Annexe", value: "annexe" }
    ]
  },
  {
    title: "Plots",
    value: "plot",
    options: [
      { label: "All Plots", value: "plot" },
      { label: "Residential Plot", value: "residential plot" },
      { label: "Commercial Plot", value: "commercial plot" },
      { label: "Agricultural Land", value: "agricultural land" },
      { label: "Industrial Land", value: "industrial land" },
      { label: "Plot File", value: "plot file" },
      { label: "Farmhouse Plot", value: "farmhouse plot" }
    ]
  },
  {
    title: "Commercial",
    value: "commercial",
    options: [
      { label: "Commercial", value: "commercial" },
      { label: "Office", value: "office" },
      { label: "Shop", value: "shop" },
      { label: "Warehouse", value: "warehouse" },
      { label: "Factory", value: "factory" },
      { label: "Building", value: "building" },
      { label: "Other", value: "other" }
    ]
  }
];

export const images = {
  placeholderImage:
  s3URL("/agency21/images/original/edghdpatlkiz7alei88u/1627475479_png"),
  placeholderUserImage:
    s3URL("/.agency21/images/original/mmaqe3tlf1pdgnmmcoju/1627626858_png")
};
