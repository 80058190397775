import { CALL_API } from "../apiMiddleware";

export const FETCH_PROJECTVIDEOS = "FETCH_PROJECTVIDEOS";
export const FETCH_PROJECTVIDEOS_SUCCESS = "FETCH_PROJECTVIDEOS_SUCCESS";
export const FETCH_PROJECTVIDEOS_FAILURE = "FETCH_PROJECTVIDEOS_FAILURE";

export const DELETE_PROJECTVIDEOS = "DELETE_PROJECTVIDEOS";
export const DELETE_PROJECTVIDEOS_SUCCESS = "DELETE_PROJECTVIDEOS_SUCCESS";
export const DELETE_PROJECTVIDEOS_FAILURE = "DELETE_PROJECTVIDEOS_FAILURE";

export const REQUEST_CREATE_PROJECTVIDEOS = "CREATE_PROJECTVIDEOS";
export const CREATE_PROJECTVIDEOS_SUCCESS = "CREATE_PROJECTVIDEOS_SUCCESS";
export const CREATE_PROJECTVIDEOS_FAILURE = "CREATE_PROJECTVIDEOS_FAILURE";

export const REQUEST_UPDATE_PROJECTVIDEOS = "UPDATE_PROJECTVIDEOS";
export const UPDATE_PROJECTVIDEOS_SUCCESS = "UPDATE_PROJECTVIDEOS_SUCCESS";
export const UPDATE_PROJECTVIDEOS_FAILURE = "UPDATE_PROJECTVIDEOS_FAILURE";

export const fetchprojectVideos = () => ({
  [CALL_API]: {
    types: [
      FETCH_PROJECTVIDEOS,
      FETCH_PROJECTVIDEOS_SUCCESS,
      FETCH_PROJECTVIDEOS_FAILURE
    ],
    endpoint: `/api/projectVideos/`,
    options: {
      method: "GET"
    }
  }
});

export const fetchprojectVideosById = id => ({
  [CALL_API]: {
    types: [
      FETCH_PROJECTVIDEOS,
      FETCH_PROJECTVIDEOS_SUCCESS,
      FETCH_PROJECTVIDEOS_FAILURE
    ],
    endpoint: `/api/projectVideos/${id}`,
    options: {
      method: "GET"
    }
  }
});

export const deleteprojectVideos = id => ({
  [CALL_API]: {
    types: [
      DELETE_PROJECTVIDEOS,
      DELETE_PROJECTVIDEOS_SUCCESS,
      DELETE_PROJECTVIDEOS_FAILURE
    ],
    endpoint: "/api/projectVideos/delete",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ id })
    }
  }
});

export const createprojectVideos = payload => {
  const formData = new FormData();
  for (let key in payload) {
    formData.set(key, payload[key]);
  }
  // console.log("Data here ==> ", formData)

  return {
    [CALL_API]: {
      types: [
        REQUEST_CREATE_PROJECTVIDEOS,
        CREATE_PROJECTVIDEOS_SUCCESS,
        CREATE_PROJECTVIDEOS_FAILURE
      ],
      endpoint: `/api/projectVideos/create`,
      options: {
        method: "POST",
        body: formData
      }
    }
  };
};

export const updateprojectVideos = payload => {
  const formData = new FormData();
  for (let key in payload) {
    formData.set(key, payload[key]);
  }

  return {
    [CALL_API]: {
      types: [
        REQUEST_UPDATE_PROJECTVIDEOS,
        UPDATE_PROJECTVIDEOS_SUCCESS,
        UPDATE_PROJECTVIDEOS_FAILURE
      ],
      endpoint: `/api/projectVideos/update/${payload.projectID}`,
      options: {
        method: "PUT",
        body: formData
      }
    }
  };
};
