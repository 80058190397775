import { toast } from "react-toastify";
// import React, { useState } from "react";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  SEND_MESSAGE,
  SEND_MESSAGE_FAILURE,
  SEND_MESSAGE_SUCCESS,
  // RESET_FORM_STATE,
} from "../../actions/franchise";

const initialState = {
  sendingMessage: false,
  messageSentStatus: "",
};

export const messageEmail = (state = initialState, action) => {
  // const [modalOpen, setModalOpen] = useState(false);
  // const [modalMessage, setModalMessage] = useState("");
  // const toggleModal = () => {
  //   setModalOpen(!modalOpen);
  // };
  switch (action.type) {
    case SEND_MESSAGE:
      return Object.assign({}, state, { sendingMessage: true });

    case SEND_MESSAGE_SUCCESS: {
      // setModalMessage("Thank you for your valuable feedback!");
      // toggleModal();
      toast.success(
        "Thanks for showing interest in Agency21 Franchise Partner Program. Our agent will contact you soon. For any further query please call on +9251-111-021-111 or email us on info.franchise@agency21.com.pk",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return Object.assign({}, state, {
        sendingMessage: false,
        messageSentStatus: "sent",
      });
    }

    case SEND_MESSAGE_FAILURE: {
      toast.error("Something went wrong, Please try later...", {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setModalMessage("Something went wrong, Please try later...");
      // toggleModal();
      return Object.assign({}, state, {
        sendingMessage: false,
        messageSent: "error",
      });
    }

    // case RESET_FORM_STATE:
    //   return Object.assign({}, state, initialState);

    default:
      return state;
  }
  // return (
  //   <Modal isOpen={modalOpen} toggle={toggleModal}>
  //     <ModalHeader toggle={toggleModal}>Message Status</ModalHeader>
  //     <ModalBody>{modalMessage}</ModalBody>
  //     <ModalFooter>
  //       <Button color="primary" onClick={toggleModal}>
  //         Close
  //       </Button>
  //     </ModalFooter>
  //   </Modal>
  // );
};
