import { CALL_API } from '../apiMiddleware';

export const FETCH_SEARCHED_LISTINGS = 'FETCH_SEARCHED_LISTINGS';
export const FETCH_SEARCHED_LISTINGS_SUCCESS = 'FETCH_SEARCHED_LISTINGS_SUCCESS';
export const FETCH_SEARCHED_LISTINGS_FAILURE = 'FETCH_SEARCHED_LISTINGS_FAILURE';

export const fetchSearchedListings = (search) => ({
    [CALL_API]: {
        types: [FETCH_SEARCHED_LISTINGS, FETCH_SEARCHED_LISTINGS_SUCCESS, FETCH_SEARCHED_LISTINGS_FAILURE],
        endpoint: `/api/listings/search${search}`,
        options: {
            method: 'GET'
        }
    }
})