import { CALL_API } from '../apiMiddleware';

export const FETCH_CITIES = 'FETCH_CITIES';
export const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
export const FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';

export const fetchCities = () => ({
    [CALL_API]:{
      types: [FETCH_CITIES, FETCH_CITIES_SUCCESS, FETCH_CITIES_FAILURE],
      endpoint: '/api/city',
      options: {
        method: 'GET'
      }
    }
  })