import React from "react";

import "./ContactAddress.scss";

const ContactAddress = ({ email, phone, address, mlink, dark }) => {
  return (
    <ul className="contact-address-ul">
      <li>
        <img
          className="li-img-icon"
          src="/images/mail-icon-n.png"
          alt="Agency21 International"
        />
        <a href={`mailto:${email}`} className={dark ? "fw-300" : ""}>
          {email}
        </a>
      </li>
      <li>
        <img
          className="li-img-icon"
          src="/images/phone-icon-n.png"
          alt="Agency21 International"
        />
        <a href={`tel:${phone}`} className={dark ? "fw-300" : ""}>
          {phone}
        </a>
      </li>
      <li>
        <img
          className="li-img-icon"
          src="/images/map-icon-n.png"
          alt="Agency21 International"
          style={{ height: "15px" }}
        />
        <a href={mlink} className={dark ? "fw-300" : ""}>
          {address}
        </a>
      </li>
    </ul>
  );
};

export default ContactAddress;

//previous email icon: https://res.cloudinary.com/agency21/image/upload/v1536839908/icons/email.svg
//previos phone icon: https://res.cloudinary.com/agency21/image/upload/v1536839945/icons/phone.svg
//previos location icon: https://res.cloudinary.com/agency21/image/upload/v1536839921/icons/location.svg
