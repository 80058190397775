import { CALL_API } from "../apiMiddleware";

export const SEND_TOUCH = "SEND_TOUCH";
export const SEND_TOUCH_SUCCESS = "SEND_TOUCH_SUCCESS";
export const SEND_TOUCH_FAILURE = "SEND_TOUCH_FAILURE";

export const RESET_TOUCH_FORM = "RESET_TOUCH_FORM";
export const RESET_FORM_STATE = "RESET_FORM_STATE";

export const resetFormState = () => ({
  type: RESET_FORM_STATE,
});

export const sendTouch = ({ name, phone }) => ({
  [CALL_API]: {
    types: [SEND_TOUCH, SEND_TOUCH_SUCCESS, SEND_TOUCH_FAILURE],
    endpoint: "/api/getInTouch/addGetInTouch",
    options: {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, phone }),
    },
  },
});
export const resetTouchForm = () => ({
  type: RESET_TOUCH_FORM,
});