import {
    FETCH_ALL_GROUPPHOTO,
    FETCH_ALL_GROUPPHOTO_SUCCESS,
    FETCH_ALL_GROUPPHOTO_FAILURE
  } from '../../actions/groupphoto';
  
  const initialState = {
    isLoading: false,
    isDataAvailable: false,
    data: []
  }
  
  export const groupphoto = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ALL_GROUPPHOTO:
        return Object.assign({}, state, { isLoading: true });
  
      case FETCH_ALL_GROUPPHOTO_SUCCESS:
        return Object.assign({}, state, { isLoading: false, isDataAvailable: action.payload.length > 0, data: action.payload });
  
      case FETCH_ALL_GROUPPHOTO_FAILURE:
        return Object.assign({}, state, initialState);
  
      default:
        return state;
    }
  }