import { CALL_API } from '../apiMiddleware';

export const FETCH_LISTING_BY_ID = 'FETCH_LISTING_BY_ID';
export const FETCH_LISTING_BY_ID_SUCCESS = 'FETCH_LISTING_BY_ID_SUCCESS';
export const FETCH_LISTING_BY_ID_FAILURE = 'FETCH_LISTING_BY_ID_FAILURE';

export const fetchListingById = (id) => ({
  [CALL_API]: {
    types: [FETCH_LISTING_BY_ID, FETCH_LISTING_BY_ID_SUCCESS, FETCH_LISTING_BY_ID_FAILURE],
    endpoint: `/api/listings/${id}`,
    options: {
      method: 'GET'
    }
  }
})
