export const createReducer = ([FETCH, SUCCESS, FAILURE]) => {
  const initialState = {
    isLoading: false,
    isDataAvailable: false,
    data: []
  }
  return (state=initialState, action) => {
    switch(action.type){
      case FETCH:
        return Object.assign({}, state, {isLoading: true});
  
      case SUCCESS:
        return Object.assign(
          {},
          state,
          {
            isLoading: false,
            isDataAvailable: action.payload.data ? (action.payload.data.length > 0) : (action.payload.length > 0),
            data: action.payload.data ? action.payload.data : action.payload
          }
        );
  
        case FAILURE:
          return Object.assign({}, state, {isLoading: false, isDataAvailable: false});
  
        default:
          return state;
    }
  }
}