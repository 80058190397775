import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import WebFont from "webfontloader";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "typeface-exo";
import "./index.scss";
import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
import { configureStore } from "./store/configureStore";

import "react-web-vector-icons/fonts";

WebFont.load({ 
  google: {
    families: ["HurmeGeometricSans3:400,500,600", "HurmeGeometricSans3:400,500,600"],
  },
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
); 
navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister();
  }
});
// registerServiceWorker();
