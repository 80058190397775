import * as React from "react";
import {
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import "./sideDraw.scss";
import { routes } from "../../config/routes";

const MenuIcon = React.lazy(() => import("@material-ui/icons/Menu"));
const data = [
  { name: "Properties", label: "properties", icon: "/svgs/Properties.svg" },
  { name: "Projects", icon: "/svgs/Projects.svg", label: "projects" },
  // { name: "Team", label: "team", icon: "/svgs/Team.svg" },
  // { name: "Gallery", label: "gallery", icon: "/svgs/Gallery.svg" },
  { name: "Blog", label: "blog", icon: "/svgs/Blog.svg" },
  { name: "Careers", label: "careers", icon: "/svgs/careers.svg" },
  { name: "Contact", label: "contact", icon: "/svgs/contact.svg" },

  // { name: "About", label: "about", icon: "/svgs/about.svg" },

  { name: "Send Inquiry", icon: "/svgs/send-inquiry.svg" },
];
export default function SideDrawer(props) {
  const [state, setState] = React.useState({
    left: false,
  });
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let routesFilter = {};

  routes.map((data, index) => {
    routesFilter[data.label] = data;
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="box"
    >
      <Link to="/">
        <img
          className="agency-logo"
          src={"/images/agency_new_logo.png"}
          alt="Agency21 International"
        />
      </Link>
      <img
        className="arrow-back"
        src={"/svgs/left-arrow.svg"}
        alt="Agency21 International"
      />
      <Divider className="logo-divider" />
      <List>
        {data.map((text, index) => (
          <ListItem
            className="gutter"
            onClick={() => {
              text.name === "Send Inquiry"
                ? props.toggleInquiryModal()
                : props.handleClickNavLink(
                    routesFilter[text.label].path,
                    routesFilter[text.label].absolute
                  );
            }}
            button
            component={text.name === "Projects" ? "a" : ""}
            href={text.name === "Projects" ? "#projects-section" : ""}
            key={text}
          >
            <div className="icons-alignment">
              <img src={text.icon} className="icons-des" />
            </div>
            <ListItemText className="text-des" primary={text.name} />
          </ListItem>
        ))}
      </List>
      <div className="app-version">App Version v2.0</div>
    </Box>
  );

  return (
    <div className={windowWidth < 992 ? "adjust-width" : "sideDraw-hide"}>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <React.Suspense fallback={<div></div>}>
            <Button onClick={toggleDrawer(anchor, true)}>
              {" "}
              <MenuIcon className="menu-icon" />
            </Button>
          </React.Suspense>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
