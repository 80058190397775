import * as ActionTypes from '../../actions';
import { toast } from 'react-toastify';

const initialState = {
  purpose: 'sell',
  city: {},
  // area: {},
  isSubmittingForm: false,
  formSubmitted: false
}

export const instantValuation = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_VALUATION_PURPOSE:
      return { ...state, purpose: action.payload }

    case ActionTypes.SET_VALUATION_CITY:
      return { ...state, city: action.payload }

    case ActionTypes.SET_VALUATION_AREA:
      return { ...state, area: action.payload }

    case ActionTypes.REQUEST_CREATE_VALUATION_QUERY:
      return { ...state, isSubmittingForm: true }

    case ActionTypes.REQUEST_CREATE_VALUATION_QUERY_FAILURE: {
      toast.error('Something went wrong, please try again later')
      return { ...state, isSubmittingForm: false, formSubmitted: false }
    }
    case ActionTypes.REQUEST_CREATE_VALUATION_QUERY_SUCCESS: {
      toast.success('Valuation query sent successfuly')
      return { ...state, isSubmittingForm: false, formSubmitted: true }
    }

    case ActionTypes.RESET_VALUATION_FORM:
      return {...initialState}


    default:
      return state
  }
}