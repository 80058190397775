import { combineReducers } from 'redux'
import { agents } from './agents'
import { listings } from './listings'
import { projects } from './projects'
import { covers } from './covers'
import { testimonials } from './testimonials'


export const home = combineReducers({
  agents,
  listings,
  projects,
  covers,
  testimonials
})