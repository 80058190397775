import React from "react";
import { ToastContainer } from "react-toastify";
import "./Layout.scss";
import Header from "../Header";
import Footer from "../Footer";

export default function Layout(props) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);
  return (
    <div className="main-app">
      {windowWidth &&
      props.data.location.pathname.split("/")[1] === "project" &&
      windowWidth < 991 ? (
        ""
      ) : (
        <Header routeName={props.data.location.pathname.split("/")[1]} />
      )}
      <div className="app-content">{props.children}</div>

      <div className="app-footer">
        {windowWidth &&
        // props.data.location.pathname.split("/")[1] === "project" &&
        windowWidth < 991 ? (
          ""
        ) : (
          <Footer className="hide" />
        )}
      </div>
      <ToastContainer position="bottom-right" toastClassName="toast-custom" />
    </div>
  );
}
