import {
    FETCH_SEARCHED_LISTINGS,
    FETCH_SEARCHED_LISTINGS_SUCCESS,
    FETCH_SEARCHED_LISTINGS_FAILURE
} from '../../actions/search';

const initialState = {
    isLoading: false,
    isDataAvailable: false,
    data: {},
}

export const listings = (state=initialState, action) => {
    switch(action.type){
        case FETCH_SEARCHED_LISTINGS:
            return Object.assign({}, state, {isLoading: true});

        case FETCH_SEARCHED_LISTINGS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                isDataAvailable: action.payload.data.length > 0,
                data: action.payload.data,
            })

        case FETCH_SEARCHED_LISTINGS_FAILURE:
            return Object.assign({}, state, initialState)
        default:
            return state;
    }
}