import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SideDraw from "./sideDraw";
// import {SearchBar,SearchIcon} from "../SearchComponents"
// import SearchIcon from "../SearchComponents/SearchIcon";
// import { SearchBar } from "../SearchComponents/SearchBar";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // Container,
  // Row
} from "reactstrap";
import debounce from "lodash/debounce";
import "./Header.scss";
import { routes } from "../../config/routes";
import InquiryForm from "../../containers/InquiryForm";
import { getScrollInfo } from "../../utils";

import axios from "axios";
//import { confirmAlert } from 'react-confirm-alert'; // Import
//import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import "../../styles/fontawesome/css/font-awesome.css";

//import Loadable from "react-loadable";
//import LoadingLoadable from "../../components/LoadingLoadable";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isInquiryModalOpen: false,
      isFancy: false,
      windowWidth: "",
      pathName: "",
      pathchange: false,
      property_id: "",
      showloader: false,
      showInvalid: false,
      default_placeholder: "",
      showSearchBar: false,
    };
  }
  toggleSearchBar = () => {
    this.setState({
      showSearchBar: this.state.showSearchBar == true ? false : true,
    });
    this.setState({ default_placeholder: "" });
  };
  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };
  async componentDidMount() {
    const path = await window.location.pathname.split("/");

    this.setState({ pathName: path[1] });

    document.addEventListener(
      "scroll",
      debounce(this.toggleHeaderTransition, 15)
    );
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  toggleHeaderTransition = (e) => {
    const scroll = getScrollInfo();
    if (scroll.y >= 30) {
      if (!this.state.isFancy) {
        this.setState({ isFancy: true });
      }
    }
    if (scroll.y <= 30) {
      if (this.state.isFancy) {
        this.setState({ isFancy: false });
      }
    }
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleInquiryModal = () => {
    this.setState({ isInquiryModalOpen: !this.state.isInquiryModalOpen });
  };
  saveSearchPropertyById = (evt) => {
    this.state.property_id = evt.target.value;
    this.setState({ default_placeholder: this.state.property_id });
  };

  saveSearchPropertyByIdKeyPress = (evt) => {
    if (evt.keyCode === 13) {
      this.doSearchPropertyById();
    }
  };

  fetchPropertyData = async (url) => {
    return await axios
      .get(url)
      .then((res) => res.data)
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          return error;
        }
      });
  };

  doSearchPropertyById = () => {
    if (this.state.property_id > 0) {
      this.setState({ showloader: true });

      console.log("doSearchPropertyById", this.state.property_id);
      //let data = fetchListingById(this.state.property_id);
      this.fetchPropertyData(
        `https://www.agency21.com.pk/api/listings/${this.state.property_id}`
      ).then((data) => {
        if (data.property) {
          /*
          let redirect_url = `https://www.agency21.com.pk/listing/${this.state.property_id}`;
          console.log(`Data exit redirect user to URL : ${redirect_url}`);
          window.location.href = redirect_url;
          */

          let redirect_path = `/listing/${this.state.property_id}`;
          console.log(`Data exit redirect user to path : ${redirect_path}`);
          this.setState({ showloader: false });
          this.props.history.push(redirect_path);
        } else {
          console.log(`Data do not exit for ID : ${this.state.property_id}`);
          this.setState({ showInvalid: true });
          this.setState({ showloader: false });
          this.state.property_id = 0;
          setTimeout(() => {
            this.setState({ default_placeholder: "" });
            this.setState({ showInvalid: false });
          }, 2000);
          /*
          confirmAlert({
            customUI: ({ onClose }) => {
              this.setState({ showloader: false });
              return (
                <div className='custom-ui'>
                  <h1>Sorry, We could not found the Property ID your are searching for</h1>
                  <button onClick={onClose}>Ok</button>
                </div>
              );
            }
          });
          */
        }
        console.log(`Data: ${data}`);
      });
      /*
      axios.get(url).then(res => {
        setResponse(res);
      });
      */
      //console.log('data',data);
    }
  };

  handleClickNavLink = (link, absolute) => {
    const { history } = this.props;
    const { isOpen } = this.state;
    if (isOpen) {
      this.setState({ isOpen: false });
    }
    if (!absolute) {
      history.push(link);
    } else {
      window.open(link, "_blank");
    }
  };

  componentWillUnmount() {
    document.removeEventListener("scroll", this.toggleHeaderTransition);
    window.removeEventListener("resize", this.handleResize);
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const {
      location: { pathname },
    } = this.props;
    // const regex = new RegExp('listing/[0-9]+');
    const navWithBorder =
      pathname === "/team" ||
      pathname === "/terms" ||
      pathname === "/privacy-policy";
    const isHome =
      pathname === "/" ||
      pathname === "/search" ||
      // pathname === "/careers" ||
      pathname === "/job-listings";

    const isHomeP = pathname === "/";
    const isSearch = pathname === "/search";

    var w = window.innerWidth;

    let rootClass = "";
    if (navWithBorder) {
      if (this.state.isFancy) {
        if (this.state.isOpen) {
          // rootClass = this.state.isFancy ? 'nav-fancy with-border' : 'nav-default with-border'
          rootClass = "nav-fancy with-border nav-fancy-collapsed";
        } else {
          rootClass = "nav-fancy with-border";
        }
      } else {
        if (this.state.isOpen) {
          rootClass = "nav-default with-border nav-default-collapsed";
        } else {
          rootClass = "nav-fancy with-border";
        }
      }
    } else {
      if (this.state.isFancy) {
        if (this.state.isOpen) {
          // rootClass = this.state.isFancy ? 'nav-fancy with-border' : 'nav-default with-border'
          rootClass = "nav-fancy nav-fancy-collapsed";
        } else {
          rootClass = "nav-fancy";
        }
      } else {
        if (this.state.isOpen) {
          rootClass = "nav-cus-default nav-cus-default-collapsed";
        } else {
          rootClass = "nav-cus-default";
        }
      }
      // rootClass = this.state.isFancy ? 'nav-fancy' : 'nav-default'
    }

    return (
      <div
        id={"header"}
        className={
          this.state.isFancy ||
          this.state.windowWidth < 768 ||
          (this.props.routeName != "" && this.props.routeName != "search")
            ? "bg-color"
            : "bg-absol"
        }
      >
        <InquiryForm
          isInquiryModalOpen={this.state.isInquiryModalOpen}
          toggleInquiryModal={this.toggleInquiryModal}
        />
        <Navbar
          className={
            this.state.windowWidth < 1060
              ? "nav-mob"
              : this.state.isFancy
              ? "nav-web landingHeader marginHeadFancy "
              : isHomeP || isSearch
              ? "nav-web landingHeader marginHead"
              : "nav-web landingHeader marginHeadFancy"
          }
          // color={isHome ? "" : "white"}
          light
          expand="lg"
        >
          <SideDraw
            handleClickNavLink={this.handleClickNavLink}
            toggleInquiryModal={this.toggleInquiryModal}
          />

          <NavbarBrand
            className={
              this.state.windowWidth < 1060
                ? "brand-mob"
                : this.state.isFancy
                ? "brand-web landingBrand fancyImg"
                : isHomeP || isSearch
                ? "brand-web landingBrand notfancyImg"
                : "brand-web landingBrand fancyImg"
            }
            onClick={() => {
              this.handleClickNavLink("/", false);
            }}
          >
            <a href="/">
              <img
                className="on-hover-logo-click"
                src={
                  this.state.isFancy
                    ? "/images/agency_new_logo_WHITE.png"
                    : "/images/agency_new_logo_WHITE.png"
                }
                alt="Agency21 International"
              />
            </a>
          </NavbarBrand>

          <div
            className={
              this.state.showInvalid
                ? "search-property-id-container showInvalid"
                : "search-property-id-container"
            }
          >
            <div
              id="search-bar"
              className={this.state.showSearchBar ? "show" : "hide"}
            >
              <input
                className={"search-id-mob"}
                type={"number"}

                placeholder={"Search by Property ID..."}

                onChange={(evt) => this.saveSearchPropertyById(evt)}
                onKeyDown={(evt) => this.saveSearchPropertyByIdKeyPress(evt)}
                value={this.state.default_placeholder}
              />

              <i
                onClick={this.doSearchPropertyById}
                className={"fa fa-search searchicon-Mob"}
              ></i>
              <span>Invalid ID</span>
              <div
                className={
                  this.state.showloader
                    ? "divLoader showloadermob"
                    : "divLoader hideloader"
                }
              >
                <div class="loading-loadable-root">
                  <div class="lds-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="search-icon" onClick={this.toggleSearchBar}>
            <i
              className={
                this.state.showSearchBar === false
                  ? "fa fa-search"
                  : "fa fa-close"
              }
            ></i>
          </div>

          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav navbar fill>
              {routes.map((route, index) => {
                const isActive =
                  (pathname.indexOf(route.path) !== -1 ||
                    route.path.indexOf(pathname) !== -1) &&
                  pathname !== "/";
                return (
                  <NavItem key={route.id || index} active={isActive}>
                    <NavLink
                      onClick={() => {
                        this.handleClickNavLink(route.path, route.absolute);
                      }}
                    >
                      <div
                        className={
                          this.state.isFancy
                            ? "route-label route-label-white separator"
                            : isHome
                            ? w < 1060
                              ? "route-label route-label-white separator"
                              : // : "custom-label custom-label-gray separator"
                                "route-label route-label-white separator"
                            : "route-label route-label-white separator"
                        }
                      >
                        {route.label === "projects" ? (
                          <a
                            className={
                              this.state.isFancy
                                ? "route-label route-label-white"
                                : isHome
                                ? w < 1060
                                  ? "route-label route-label-white"
                                  : "custom-label custom-label-gray"
                                : "route-label route-label-white"
                            }
                            href="#projects-section"
                            style={{ textDecoration: "none" }}
                          >
                            {route.label}
                          </a>
                        ) : route.label === "about" ? (
                          <a
                            className={
                              this.state.isFancy
                                ? "route-label route-label-white"
                                : isHome
                                ? w < 1060
                                  ? "route-label route-label-white"
                                  : "custom-label custom-label-gray"
                                : "route-label route-label-white"
                            }
                            href={route.path}
                            style={{ textDecoration: "none" }}
                          >
                            {route.label}
                          </a>
                        ) : route.label === "properties" ? (
                          <a
                            className={
                              this.state.isFancy
                                ? "route-label route-label-white"
                                : isHome
                                ? w < 1060
                                  ? "route-label route-label-white"
                                  : "custom-label custom-label-gray"
                                : "route-label route-label-white"
                            }
                            href={route.path}
                            style={{ textDecoration: "none" }}
                          >
                            {route.label}
                          </a>
                        ) : route.label === "gallery" ? (
                          <a
                            className={
                              this.state.isFancy
                                ? "route-label route-label-white"
                                : isHome
                                ? w < 1060
                                  ? "route-label route-label-white"
                                  : "custom-label custom-label-gray"
                                : "route-label route-label-white"
                            }
                            href={route.path}
                            style={{ textDecoration: "none" }}
                          >
                            {route.label}
                          </a>
                        ) : route.label === "blog" ? (
                          <a
                            className={
                              this.state.isFancy
                                ? "route-label route-label-white"
                                : isHome
                                ? w < 1060
                                  ? "route-label route-label-white"
                                  : "custom-label custom-label-gray"
                                : "route-label route-label-white"
                            }
                            href={route.path}
                            style={{ textDecoration: "none" }}
                          >
                            {route.label}
                          </a>
                        ) : route.label === "careers" ? (
                          <a
                            className={
                              this.state.isFancy
                                ? "route-label route-label-white"
                                : isHome
                                ? w < 1060
                                  ? "route-label route-label-white"
                                  : "custom-label custom-label-gray"
                                : "route-label route-label-white"
                            }
                            href={route.path}
                            style={{ textDecoration: "none" }}
                          >
                            {route.label}
                          </a>
                        ) : route.label === "contact" ? (
                          <a
                            className={
                              this.state.isFancy
                                ? "route-label route-label-white"
                                : isHome
                                ? w < 1060
                                  ? "route-label route-label-white"
                                  : "custom-label custom-label-gray"
                                : "route-label route-label-white"
                            }
                            href={route.path}
                            style={{ textDecoration: "none" }}
                          >
                            {route.label}
                          </a>
                        ) : (
                          route.label
                        )}
                        <div
                          className={
                            isActive
                              ? "route-border route-border-active"
                              : "route-border"
                          }
                        />
                      </div>
                    </NavLink>
                  </NavItem>
                );
              })}
              <NavItem>
                <div
                  className={
                    this.state.showInvalid
                      ? "search-property-id-container showInvalid"
                      : "search-property-id-container"
                  }
                >
                  <input
                    className={"search-property-id"}
                    type={"text"}
                    placeholder={"Property ID"}
                    onChange={(evt) => this.saveSearchPropertyById(evt)}
                    onKeyDown={(evt) =>
                      this.saveSearchPropertyByIdKeyPress(evt)
                    }
                    value={this.state.default_placeholder}
                  />
                  <i
                    onClick={this.doSearchPropertyById}
                    className={"fa fa-search"}
                  ></i>
                  <span>Invalid ID</span>
                  <div
                    className={
                      this.state.showloader
                        ? "divLoader showloader"
                        : "divLoader hideloader"
                    }
                  >
                    <div className={"loading-loadable-root"}>
                      <div className={"lds-ripple"}>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </NavItem>
              {/* {!regex.test(pathname) && */}
              <NavItem className={w < 992 ? "cus-display-button" : ""}>
                <button
                  className={
                    this.state.isFancy
                      ? "send-inquiry-bt bt-fancy"
                      : // : isHome
                        // ? "custom-inquiry custom-default"
                        "send-inquiry-bt bt-fancy"
                    // : "send-inquiry-bt bt-default"
                  }
                  onClick={this.toggleInquiryModal}
                >
                  send inquiry
                </button>
              </NavItem>
              {/* } */}
            </Nav>
          </Collapse>
        </Navbar>
        <div
          className={
            this.state.showloader
              ? "divLoader showloader"
              : "divLoader hideloader"
          }
        >
          <svg
            className="svgLoader"
            viewBox="0 0 1024 1024"
            width="10em"
            height="10em"
          >
            <path fill="lightblue" d="PATH FOR THE LOADER ICON" />
          </svg>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
