import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SEND_TOUCH,
  SEND_TOUCH_SUCCESS,
  SEND_TOUCH_FAILURE,
  RESET_TOUCH_FORM,
} from "../../actions/getInTouchForm";

const initialState = {
  sendingInTouch: false,
  touchSentStatus: "",
};

export const getInTouchRed = (state = initialState, action) => {
  switch (action.type) {
    case SEND_TOUCH:
      return Object.assign({}, state, { sendingInTouch: true });

    case SEND_TOUCH_SUCCESS: {
      toast.success("Message send successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        sendingInTouch: false,
        touchSentStatus: "sent",
      });
    }

    case SEND_TOUCH_FAILURE: {
      if (action.payload === 409) {
        toast.error("You have already submitted a request", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(
          "Could not send Get In Touch at this moment, please try again later...",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
      return Object.assign({}, state, {
        sendingInTouch: false,
        touchSentStatus: "error",
      });
    }

    case RESET_TOUCH_FORM: {
      return Object.assign({}, state, initialState);
    }

    default:
      return state;
  }
};
