import * as ActionTypes from "../../actions";

import { shiftVisibleArrayToLeft, shiftVisibleArrayToRight } from "../../utils";

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: [],
  visibleData: {
    startIndex: 0,
    endIndex: 3,
    perPage: 3,
  },
};

export const projects = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_PROJECTS:
      return Object.assign({}, state, { isLoading: true });

    case ActionTypes.FETCH_PROJECTS_SUCCESS:
      console.log('action',action.payload)
      return Object.assign({}, state, {
        isLoading: false,
        isDataAvailable: action.payload.data
          ? action.payload.data.length > 0
          : action.payload.length > 0,
        data: action.payload.data ? action.payload.data : action.payload,
      });

    case ActionTypes.FETCH_PROJECTS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isDataAvailable: false,
      });

    case ActionTypes.SET_INITIAL_PROJECTS_VISIBLE_DATA:
      return Object.assign({}, state, { visibleData: action.payload });

    case ActionTypes.INCREMENT_PROJECTS_CURRENT_PAGE: {
      const {
        data,
        visibleData: { startIndex, endIndex, perPage },
      } = state;
      const [nextStartIndex, nextEndIndex] = shiftVisibleArrayToRight(
        data.length,
        startIndex,
        endIndex,
        perPage
      );

      return Object.assign({}, state, {
        visibleData: Object.assign({}, state.visibleData, {
          startIndex: nextStartIndex,
          endIndex: nextEndIndex,
        }),
      });
    }

    case ActionTypes.DECREMENT_PROJECTS_CURRENT_PAGE: {
      const {
        data,
        visibleData: { startIndex, endIndex, perPage },
      } = state;
      const [nextStartIndex, nextEndIndex] = shiftVisibleArrayToLeft(
        data.length,
        startIndex,
        endIndex,
        perPage
      );

      return Object.assign({}, state, {
        visibleData: Object.assign({}, state.visibleData, {
          startIndex: nextStartIndex,
          endIndex: nextEndIndex,
        }),
      });
    }

    default:
      return state;
  }
};
