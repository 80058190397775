import { combineReducers } from 'redux'
import * as ActionTypes from '../../actions/home'

const data = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TESTIMONIALS_SUCCESS:
      return action.payload

    default:
      return state
  }
}

const isLoading = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TESTIMONIALS:
      return true

    case ActionTypes.FETCH_TESTIMONIALS_SUCCESS:
    case ActionTypes.FETCH_TESTIMONIALS_FAILURE:
      return false

    default:
      return state
  }
}

const isDataAvailable = (state = false, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TESTIMONIALS_FAILURE:
      return false

    case ActionTypes.FETCH_TESTIMONIALS_SUCCESS:
      return (action.payload.length > 0) ? true : false
    default:
      return state
  }
}

export const testimonials = combineReducers({
  data,
  isLoading,
  isDataAvailable
})