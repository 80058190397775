import { FETCH_AGENTS, FETCH_AGENTS_SUCCESS, FETCH_AGENTS_FAILURE } from '../../actions/home';

const initialState = {
  isLoading: false,
  isDataAvailable: false,
  data: []
};

export const agents = (state=initialState, action) => {
  switch(action.type){
    case FETCH_AGENTS:
      return Object.assign({}, state, {isLoading: true});

    case FETCH_AGENTS_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isLoading: false,
          isDataAvailable: action.payload.data.length > 0,
          data: action.payload.data
        }
      );

      case FETCH_AGENTS_FAILURE:
        return Object.assign({}, state, {isLoading: false, isDataAvailable: false});

      default:
        return state;
  }
}