import {
  FETCH_ALL_JOBS,
  FETCH_ALL_JOBS_SUCCESS,
  FETCH_ALL_JOBS_FAILURE,
  FETCH_JOB_DETAILS,
  FETCH_JOB_DETAILS_SUCCESS,
  FETCH_JOB_DETAILS_FAILURE,
  APPLY_FOR_JOB,
  APPLY_FOR_JOB_SUCCESS,
  APPLY_FOR_JOB_FAILURE,
  RESET_JOB_APPLICATION_FORM,
  SET_CURRENT_JOB_APPLICATION_ID,
  SET_CURRENT_JOB_APPLICATION_TITLE,
  UPLOAD_CV,
  UPLOAD_CV_SUCCESS,
  UPLOAD_CV_FAILURE,
  RESET_RESUME,
  SET_CURRENT_JOB_PAGE,
} from "../../actions/jobs";
import { toast } from "react-toastify";

const initialState = {
  data: [],
  jobDetails: null,
  isLoading: false,
  isDataAvailable: false,
  applyingForJob: false,
  jobApplicationStatus: "",
  currentJobApplicationId: "",
  currentJobApplicationTitle: "",
  currentResumeLink: "",
  isResumeUploading: false,
  resumeUploadStatus: "",
  pageSize: 10,
  totalJobs: 0,
  currentJobPage: 1,
};

export const jobs = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_JOBS:
      return Object.assign({}, state, { isLoading: true });
    case FETCH_ALL_JOBS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        isDataAvailable: action.payload.data.length > 0,
        data: action.payload.data,
      });
    case FETCH_ALL_JOBS_FAILURE:
      return Object.assign({}, state, initialState);
    case FETCH_JOB_DETAILS:
      return Object.assign({}, state, { isLoading: true });
    case FETCH_JOB_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isLoading: false,
        isDataAvailable: action.payload.data,
        jobDetails: action.payload.data,
      });
    case FETCH_JOB_DETAILS_FAILURE:
      return Object.assign({}, state, initialState);
    case APPLY_FOR_JOB:
      return Object.assign({}, state, { applyingForJob: true });
    case APPLY_FOR_JOB_SUCCESS:
      toast.success("Job Application submitted Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        applyingForJob: false,
        jobApplicationStatus: "sent",
      });
    case APPLY_FOR_JOB_FAILURE:
      // console.log(action.payload);
      toast.error("Invalid Job Post Application", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        applyingForJob: false,
        inquirySentStatus: "error",
      });
    case RESET_JOB_APPLICATION_FORM:
      return Object.assign({}, state, initialState);
    case SET_CURRENT_JOB_APPLICATION_ID:
      return Object.assign({}, state, {
        currentJobApplicationId: action.payload,
      });
    case SET_CURRENT_JOB_APPLICATION_TITLE:
      return Object.assign({}, state, {
        currentJobApplicationTitle: action.payload,
      });
    case UPLOAD_CV:
      return Object.assign({}, state, { isResumeUploading: true });
    case UPLOAD_CV_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isResumeUploading: false,
        resumeUploadStatus: "uploaded",
        currentResumeLink: action.payload,
      });
    case UPLOAD_CV_FAILURE:
      toast.error("Invalid Job Post Application", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return Object.assign({}, state, {
        isResumeUploading: false,
        resumeUploadStatus: "error",
      });
    case RESET_RESUME:
      return Object.assign({}, state, {
        currentResumeLink: "",
        resumeUploadStatus: "",
        jobApplicationStatus: "",
      });
    case SET_CURRENT_JOB_PAGE:
      return Object.assign({}, state, { currentJobPage: action.payload });
    default:
      return state;
  }
};
